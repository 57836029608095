import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {instance} from '../../api/api';


export const fetchAmendments = createAsyncThunk(
    'amendments/fetchAmendments',
    async (realm_id) => {
        const response = await
            instance.get(`/amendments/${realm_id}`);
        return response.data
    }
);

const initialState = {
    amendments: [],
    status: 'idle',
    error: ''
}

const getAmendmentsSlice = createSlice({
    name: 'amendments',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAmendments.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(fetchAmendments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.amendments = action.payload;
                state.error = ""
            })
            .addCase(fetchAmendments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

    }
});

export default getAmendmentsSlice.reducer;
