import React from 'react'
import {Table} from 'semantic-ui-react'
import {formatDate} from '../../functions/date'
import './UserPage.css'

const MyRequestsTable = ({myRequests}) => {
    return (
        <Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>Дата</Table.HeaderCell>
                        <Table.HeaderCell>Область</Table.HeaderCell>
                        <Table.HeaderCell>Комментарий к запросу</Table.HeaderCell>
                        <Table.HeaderCell>Статус запроса</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {myRequests.map((it, index) => {
                        const formattedDate = formatDate(it.createdDate)
                        let status = ""
                        if (it.status === "rejected") {
                            status = "Запрос отклонён"
                        } else if (it.status === "accepted") {
                            status = "Доступ к области предоставлен"
                        } else if (it.status === "new") {
                            status = "Запрос отправлен"
                        }
                        return <Table.Row key={index}>
                            <Table.Cell collapsing/>
                            <Table.Cell style={{width: "200px"}}>{formattedDate}</Table.Cell>
                            <Table.Cell style={{width: "300px"}}>{it.realm.name}</Table.Cell>
                            <Table.Cell style={{width: "300px"}}>{it.comment}</Table.Cell>
                            <Table.Cell style={{width: "300px"}}>{status}</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
        )
}

export default MyRequestsTable

