import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {instance} from '../../api/api';


export const deleteAmendment = createAsyncThunk(
    'delete/deleteAmendment',
    async (amendment_id) => {
        const response = await
            instance.delete(`/amendment/${amendment_id}`);
        return response.data
    }
);

const initialState = {
    amendment: {},
    status: 'idle',
    error: ''
}

const deleteAmendmentSlice = createSlice({
    name: 'delete',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(deleteAmendment.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(deleteAmendment.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.amendment = {};
                state.error = ""
            })
            .addCase(deleteAmendment.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

    }
});

export default deleteAmendmentSlice.reducer;
