import React, {useState} from 'react';
import NewUserFormJSX from './NewUserFormJSX'

const NewUserForm = ({userContact, userSocialLink, userCoverLetter}) => {
    const [coverLetter, setCoverLetter] = useState(userCoverLetter)
    const [contact, setContact] = useState(userContact)
    const [socialLink, setSocialLink] = useState(userSocialLink)

    const [open, setOpen] = useState(false);

    const [isCoverLetterValid, setCoverLetterValid] = useState(true);
    const [coverLetterTouched, setCoverLetterTouched] = useState(false);

    const [isContactValid, setContactIsValid] = useState(true);
    const [contactTouched, setContactTouched] = useState(false);

    const onCoverLetterChange = (value) => {
        setCoverLetter(value)
    }
    const onContactChange = (value) => {
        setContact(value)
    }

    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <NewUserFormJSX setSocialLink={setSocialLink} coverLetterTouched={coverLetterTouched}
                        setCoverLetterValid={setCoverLetterValid} onCoverLetterChange={onCoverLetterChange}
                        contactTouched={contactTouched} setContactIsValid={setContactIsValid}
                        onContactChange={onContactChange} setCoverLetterTouched={setCoverLetterTouched}
                        coverLetter={coverLetter} setContactTouched={setContactTouched} contact={contact}
                        socialLink={socialLink} handleOpen={handleOpen} handleClose={handleClose}
                        isCoverLetterValid={isCoverLetterValid} isContactValid={isContactValid} open={open}/>
    );
};

export default NewUserForm;

