import {createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api';

const initialState = {
    id: ""
}

export const manageLawyersSlice = createSlice({
    name: 'manageLawyers',
    initialState,
    reducers: {
        manageLawyer: (state, action) => {
            return {
                ...state,
                id: action.payload.id
            };
        }
    }
});

export const {manageLawyer} = manageLawyersSlice.actions;

export const allowLawyer = (lawyer_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/lawyer/allow/${lawyer_id}`)
            .then((response) => {
                dispatch(manageLawyer({lawyer_id}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
}

export const disallowLawyer = (lawyer_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/lawyer/disallow/${lawyer_id}`)
            .then((response) => {
                dispatch(manageLawyer({lawyer_id}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response?.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export const banLawyer = (lawyer_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/lawyer/ban/${lawyer_id}`)
            .then((response) => {
                dispatch(manageLawyer({lawyer_id}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export default manageLawyersSlice.reducer;
