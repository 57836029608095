import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {instance} from '../../api/api'


export const getNewRequests = createAsyncThunk(
    'newRequests/getNewRequests',
    async (reqStatus) => {
        const response = await
            instance.get(`/requests/${reqStatus}`)
        console.log(response.data)
        return response.data
    }
);


const initialState = {
    newRequests: [],
    status: 'idle',
    error: ''
}

const getNewRealmRequestsSlice = createSlice({
    name: 'newRequests',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getNewRequests.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getNewRequests.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.requests = action.payload;
                state.error = ""
            })
            .addCase(getNewRequests.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export default getNewRealmRequestsSlice.reducer;
