import React from 'react';
import {Link} from 'react-router-dom'
import SocialLinks from './SocialLinks'
import {Segment} from 'semantic-ui-react'

const Footer = () => (
    <footer>
        <Segment basic>
            <SocialLinks/>
            <div><Link to={'/terms'}>Terms of service</Link></div>
            <div><Link to={'/policy'}>Privacy policy</Link></div>
        </Segment>
    </footer>
);

export default Footer;

