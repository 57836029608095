import React from 'react';
import {Icon, List, Container} from 'semantic-ui-react'

const SocialLinks = () => (
            <Container textAlign='center'>
                <List horizontal>
                    <List.Item>
                        <List.Content>
                            <List.Header>
                                <a href="https://t.me/antiprinter"
                                   rel="noreferrer noopener"
                                   target="_blank">
                                    <Icon color='blue'
                                          name='telegram'
                                          size='big'/>
                                </a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            <List.Header>
                                <a href="https://www.facebook.com/sanatsia"
                                   rel="noreferrer noopener"
                                   target="_blank">
                                    <Icon color='blue'
                                          name='facebook'
                                          size='big'/>
                                </a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            <List.Header>
                                <a href="https://twitter.com/sanatsia"
                                   rel="noreferrer noopener"
                                   target="_blank">
                                    <Icon color='blue'
                                          name='twitter'
                                          size='big'/>
                                </a>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                </List>
            </Container>
);

export default SocialLinks;

