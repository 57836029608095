import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api'


export const fetchPreparedAmendments = createAsyncThunk(
    'preparedAmendments/fetchMyPreparedAmendments',
    async () => {
        const response = await
            instance.get(`/amendments/prepared`);
        return response.data
    }
);

const initialState = {
    preparedAmendments: [],
    status: 'idle',
    error: ''
}

const getPreparedAmendmentsSlice = createSlice({
    name: 'preparedAmendments',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchPreparedAmendments.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(fetchPreparedAmendments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.preparedAmendments = action.payload;
                state.error = ""
            })
            .addCase(fetchPreparedAmendments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

    }
});

export default getPreparedAmendmentsSlice.reducer;
