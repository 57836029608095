export const iconStyle = {
    marginLeft: "40%"
}

export const buttonWidth = {
    width: "100%"
}

export const widthAutoCell = {
    width: "auto"
}

export const diplomButtonStyle = {
    maxWidth: "100px",
    fontSize: "10px"
}
