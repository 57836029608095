import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {Button, Segment, Icon} from 'semantic-ui-react'
import {fetchAmendments} from '../../../../redux/reducers/getAmendmentsSlice'
import {fetchRealm} from '../../../../redux/reducers/getRealmSlise'
import {useParams} from 'react-router-dom';
import ButtonClose from '../Buttons/ButtonClose'
import AmendmentsListText from './AmendmentsListText'
import PromptModal from '../../../Modals/PromptModal'
import MessageModal from '../../../Modals/MessageModal'
import {buttonStyle, buttonRequestStyle} from './styles'
import './AmendmentsList.css'

const AmendmentsList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {docId, id} = useParams();
    const [openModal, setOpenModal] = useState(false)
    const [openMessage, setOpenMessage] = useState(false)
    const realm = useSelector(state => state.realmReducer.realm)
    const hasRight = realm.hasRight
    useEffect(() => {
        dispatch(fetchRealm(id))
        if (hasRight) {
            dispatch(fetchAmendments(id))
        }
    }, [dispatch, id, hasRight])
    const amendments = useSelector(state => state.amendmentsReducer.amendments)
    const status = useSelector(state => state.amendmentsReducer.status)
    const userStatus = localStorage.getItem("status")

    const handleClose = () => {
        navigate(`/user/docs/realms/${docId}`)
    }
    const handleOpen = () => {
        navigate(`/user/docs/realm/amendment/${docId}/${id}`)
    }

    return (
        <Segment basic className="basicSegment">
            <ButtonClose handleClose={handleClose}/>
            {hasRight ?
                (<Button
                    onClick={handleOpen}
                    className="triggerButton"
                    style={buttonStyle}
                    color="blue">
                    Создать свою поправку в "{realm.name}"
                </Button>) : (<><Button
                    onClick={() => {
                        setOpenModal(true)
                    }}
                    className="triggerButton"
                    style={buttonRequestStyle}
                    color="green">
                    Запросить доступ к "{realm.name}"
                </Button>
                    <PromptModal
                        open={openModal}
                        action={setOpenModal}
                        setOpenMessage={setOpenMessage}
                        id={id}/>
                    <MessageModal open={openMessage}
                                  path={null}
                                  action={setOpenMessage}
                                  message="Запрос отправлен. Статус запроса вы можете посмотреть в Мои запросы"
                                  id={null}
                                  reload={null}/>
                </>)}
            {userStatus === "moderator" && <Button
                onClick={() => {
                    navigate(`/editrealm/${docId}/${id}`)
                }}
                color="blue"
                floated='right'
                className="triggerButton"
                style={buttonStyle}>
                Редактировать
            </Button>}
            <div style={{fontSize: "18px", marginTop: "3rem"}}>
                Количество поправок: {amendments.length}
            </div>
            <div style={{fontSize: "18px", marginTop: "1rem"}}>
                Описание: {realm.description}
            </div>
            {hasRight && <>
                <div style={{fontSize: "18px", marginTop: "1rem"}}>
                    Текст, начинающий статью поправочного закона: {realm.startingText}
                </div>
                <div style={{fontSize: "18px", marginTop: "1rem"}}>
                    Ссылки на Собрание Законодательства РФ: {realm.codex}
                </div>
            </>}
            <Segment>
                <b style={{fontSize: "18px"}}>Список поправок "{realm.name}":</b>
            </Segment>
            <div>
                {status === "loading" ?
                    <div className="preloader">
                        <Icon loading name='spinner' size='massive'/>
                    </div> :
                    (<AmendmentsListText
                            amendments={amendments}
                            realm={realm}
                            id={id}/>
                    )}
            </div>
        </Segment>
    )
}
export default AmendmentsList
