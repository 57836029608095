import React from 'react';
import {Card} from 'semantic-ui-react';
import {cardStyle, lineStyle, cardDescriptionStyle} from './styles'
import './Main.css'

const PdfPreview = ({ pdf, header}) => {
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    return (
        <a href={pdf}
           rel="noreferrer noopener"
           target="_blank"
           style={{ display: 'block',
               textDecoration: 'none',
               color: 'inherit' }}>
        <Card centered color='blue' style={cardStyle}>
            <Card.Content header={header} style={lineStyle}/>
            <Card.Content style={cardDescriptionStyle} description="Нажмите, чтобы открыть полный текст документа" />
            {/*<Modal open={open} onClose={handleClose} basic size='fullscreen'>*/}
            {/*    <Modal.Content>*/}
            {/*        <iframe title="pdf-preview" src={pdf} width="100%" height="800px"></iframe>*/}
            {/*    </Modal.Content>*/}
            {/*</Modal>*/}
        </Card>
</a>
    );
};
export default PdfPreview
