import React from 'react'
import {Button, Icon} from 'semantic-ui-react'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'


const AmendmentMessageButton = ({message, path, action, id, reload}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return (
        <Button
            style={{marginTop: "1%", width: "250px"}}
            onClick={() => {
                if (reload) {
                    dispatch(reload(id))
                }
                navigate(path)
                action(false)
            }}>
            {message}
            <Icon name='close'
                  color="orange"
                  size='big'
                  style={{
                      marginLeft: "10px",
                      marginBottom: "5px"
                  }}/>
        </Button>)

}
export default AmendmentMessageButton
