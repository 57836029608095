import React from 'react';
import {NavLink} from 'react-router-dom';
import {Icon, Menu} from 'semantic-ui-react';


const EditsMenu = () => (
    <div>
        <Menu.Item as={NavLink} to={'/editdashboard/requests'} exact>
            <Icon name='question circle outline'/>
            Запросы
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/editdashboard/approved'}>
            <Icon name='checkmark'/>
            Выданные
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/editdashboard/disallow'}>
            <Icon name='minus circle'/>
            Отклонённые
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/editdashboard/prepared'}>
            <Icon name='tablet alternate'/>
            К публикации
        </Menu.Item>
    </div>
)

export default EditsMenu

