import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {validate} from './realmValidation'
import {useParams, useLocation} from 'react-router-dom';
import {useFormik} from 'formik'
import {fetchRealm} from '../../../redux/reducers/getRealmSlise'
import RealmEditorForm from './RealmEditorForm'
import "./AmendmentsEditor.css";

const RealmEditor = ({title, initialValues, action, message, realmId}) => {
    const dispatch = useDispatch()
    const {docId, id} = useParams();
    const location = useLocation();
    const realm = useSelector(state => state.realmReducer.realm)

    const [newRealmId, setNewRealmId] = useState('')

    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [openDel, setOpenDel] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenModal = (id) => {
        setOpenModal(true);
    };

    const handleCloseModal = (id) => {
        setOpenModal(false);
    };


    const handleChangeName = (value) => {
        formik.setFieldValue(`name`, value);
        localStorage.setItem(`${realmId}:name`, formik.values.name)
    };

    const handleChangeDescription = (value) => {
        formik.setFieldValue(`description`, value);
        localStorage.setItem(`${realmId}:description`, formik.values.description)
    };

    const handleChangeStartingText = (value) => {
        formik.setFieldValue(`startingText`, value);
        localStorage.setItem(`${realmId}:startingText`, formik.values.startingText)
    };

    const handleChangeCodex = (value) => {
        formik.setFieldValue(`codex`, value);
        localStorage.setItem(`${realmId}:codex`, formik.values.codex)
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (formData) => {
            dispatch(action(
                formData.id,
                formData.name,
                formData.description,
                formData.startingText,
                formData.codex,
                formData.document = {...realm.document}
            )).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (response.data.id) {
                        setNewRealmId(response.data.id)
                    }
                    handleOpen()
                }
            })
                .catch((error) => {
                    alert("Извините, произошла ошибка добавления")
                });
        },
        validate
    });

    useEffect(() => {
        if (location.pathname === `/editrealm/${docId}/${id}`) {
            dispatch(fetchRealm(id))
                .then((response) => {
                    if (realm.name !== undefined) {
                        localStorage.setItem(`${id}:name`, realm.name);
                    }
                    if (realm.description !== undefined) {
                        localStorage.setItem(`${id}:description`, realm.description);
                    }
                    if (realm.id !== undefined) {
                        localStorage.setItem(`${id}:id`, realm.id);
                    }
                    if (realm.startingText !== undefined) {
                        localStorage.setItem(`${id}:startingText`, realm.startingText);
                    }
                    if (realm.codex !== undefined) {
                        localStorage.setItem(`${id}:codex`, realm.codex);
                    }
                })
                .then(() => {
                    const name = localStorage.getItem(`${id}:name`);
                    const description = localStorage.getItem(`${id}:description`);
                    const realmId = localStorage.getItem(`${id}:id`)
                    const startingText = localStorage.getItem(`${id}:startingText`)
                    const codex = localStorage.getItem(`${id}:codex`)
                    formik.setValues({
                        ...formik.values,
                        name: name || initialValues.name,
                        description: description || initialValues.description,
                        id: realmId || initialValues.id,
                        startingText: startingText || initialValues.startingText,
                        codex: codex || initialValues.codex,
                    });
                })
        }
        // eslint-disable-next-line
    }, [dispatch, id])

    return (
        <RealmEditorForm formik={formik} handleChangeName={handleChangeName}
                         handleChangeDescription={handleChangeDescription}
                         handleChangeStartingText={handleChangeStartingText} title={title}
                         handleChangeCodex={handleChangeCodex} initialValues={initialValues} action={action}
                         message={message} docId={docId} realmId={realmId} handleOpenModal={handleOpenModal}
                         handleClose={handleClose} newRealmId={newRealmId} openModal={openModal}
                         handleCloseModal={handleCloseModal} setOpenDel={setOpenDel} openDel={openDel} open={open}/>
    );
};
export default RealmEditor;
