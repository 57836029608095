

export const required = true;
export const codexRequired = true;

export const modules = {
    toolbar: [
        ["bold", "italic"],
    ]
};

export const onMainTextChange = (setMainText, mainText, id) => {
    setMainText(mainText)
    localStorage.setItem(`${id}:mainText`, mainText);
}
export const handleClear = (setMainText, setSupplementaryText, setFinanceText, setRevocationText, setCodex) => {
    setMainText("");
    setSupplementaryText("");
    setFinanceText("");
    setRevocationText("");
    setCodex("");
    localStorage.removeItem("mainText");
    localStorage.removeItem("supplementaryText");
    localStorage.removeItem("financeText");
    localStorage.removeItem("revocationText");
    localStorage.removeItem("codex");
}
