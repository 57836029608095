import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Table, Button, Icon, Segment, Image} from 'semantic-ui-react'
import {getRequests} from '../../../redux/reducers/getRealmRequestsSlise'
import {getNewRequests} from '../../../redux/reducers/getNewRealmRequestsSlise'
import {formatDate} from '../../../functions/date'
import {grantTheLawyerRealm, revokeTheLawyerRealm} from '../../../redux/reducers/lawyerRealmSlise'

const RequestsEdits = () => {
    const dispatch = useDispatch()
    const [edits, setEdits] = useState([])
    const status = "new"
    useEffect(() => {
        dispatch(getRequests(status))
    }, [dispatch])
    const requests = useSelector(state => state.getRealmRequestsSlice.requests)

    const showPreloader = (itemId) => {
        if (edits.includes(itemId)) {
            setEdits(edits.filter(id => id !== itemId));
        } else {
            setEdits([...edits, itemId]);
        }
    }

    return <Segment basic style={{width: "90%"}}>
        {!requests ?
            (<div className="preloader">
                <Icon loading name='spinner' size='massive'/>
                <div><h2>Loading...</h2></div>
            </div>) :
            (<Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><Icon name='check'
                                                size='large'
                                                style={{marginLeft: "40%"}}/></Table.HeaderCell>
                        <Table.HeaderCell>Дата</Table.HeaderCell>
                        <Table.HeaderCell>Аватар</Table.HeaderCell>
                        <Table.HeaderCell>Имя</Table.HeaderCell>
                        <Table.HeaderCell>Область</Table.HeaderCell>
                        <Table.HeaderCell>Комментарий к запросу</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {requests.map((it, index) => {
                        const formattedDate = formatDate(it.createdDate)
                        return <Table.Row key={index}>
                            <Table.Cell collapsing>
                                {!edits.includes(it.id) ?
                                    <><Button inverted color='green'
                                        onClick={() => {
                                            showPreloader(it.id)
                                            dispatch(grantTheLawyerRealm(it.id))
                                                .then(() => {
                                                    dispatch(getRequests(status));
                                                    dispatch(getNewRequests(status))
                                                })
                                        }}>Разрешить</Button>
                                    <Button inverted color='red'
                                        onClick={() => {
                                            showPreloader(it.id)
                                            dispatch(revokeTheLawyerRealm(it.id))
                                                .then(() => {
                                                    dispatch(getRequests(status));
                                                    dispatch(getNewRequests(status))
                                                })
                                        }}
                                >Отклонить</Button></>:
                                    (<Icon loading name='spinner' size='big'/>)}
                            </Table.Cell>
                            <Table.Cell style={{width: "200px"}}>{formattedDate}</Table.Cell>
                            <Table.Cell style={{width: "60px"}}>{it.avatar ?
                                (<Image circular src={it.lawyer.avatar} avatar size='mini'/>)
                                : (<Image circular src='https://cdn-icons-png.flaticon.com/512/6596/6596121.png' avatar
                                          size='mini'/>)}
                            </Table.Cell>
                            <Table.Cell style={{width: "400px"}}>{it.lawyer.name}</Table.Cell>
                            <Table.Cell style={{width: "300px"}}>{it.realm.name}</Table.Cell>
                            <Table.Cell style={{width: "300px"}}>{it.comment}</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>)}
    </Segment>
}

export default RequestsEdits

