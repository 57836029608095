import React from 'react'
import {Button, Icon} from 'semantic-ui-react'
import {useNavigate} from 'react-router-dom'

const ButtonBack = () => {
    const navigate = useNavigate()
    return (
    <Button
        onClick={() => {
            navigate(-1)}}
        className="triggerButton"
        size='mini'
        style={{marginBottom: "2px",  backgroundColor: "gainsboro"}}
    >
        <Icon name='long arrow alternate left'
              color="orange"/>
    </Button>
    )
}
export default ButtonBack
