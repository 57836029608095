import {createSlice} from '@reduxjs/toolkit'
import {instance} from '../../api/api'

const initialState = {
    profile: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getProfile(state, action) {
            state.profile = action.payload;
        },
        removeProfile(state, action) {
            state.profile = null
        }
    }
})

export const {getProfile, removeProfile} = userSlice.actions

export const getUser = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.get(`/profile`)
            .then((response) => {
                const profile = response.data;
                const status = localStorage.getItem("status")
                if (status === undefined || status === null) {
                    localStorage.setItem("status", response.data.status)
                }
                dispatch(getProfile(profile));
                resolve(response);
            })
            .catch((error) => {
                if ((error.response && error.response.status === 401)) {
                    window.location = '/'
                }
            });
    });
};

export const removeUser = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/auth/logout`)
            .then((response) => {
                    dispatch(removeProfile());
                    localStorage.removeItem("status");
                    localStorage.removeItem("token");
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem("status");
                    localStorage.removeItem("token");
                    window.location = '/'
                }
                reject(error);
            });
    });
};

export default userSlice.reducer
