import React, {useState} from 'react'
import {Form, Button} from 'semantic-ui-react'
import {useDispatch} from 'react-redux'
import {addNewComment} from '../../../../../redux/reducers/commentAmendmentSlise'
import {fetchComments} from '../../../../../redux/reducers/getCommentsSlise'
import './CommentForm.css'

const CommentForm = ({id, commented}) => {
    const dispatch = useDispatch()
    const [comment_text, setText] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(addNewComment(id, commented, comment_text))
            .then(() => {
                dispatch(fetchComments(id))
                setText('');
            })
    };
    return (
        <Form style={{width: "100%"}}
              onSubmit={handleSubmit}>
            <Form.TextArea
                className="textarea"
                label='Напишите свой комментарий'
                placeholder='Текст комментария'
                name='text'
                value={comment_text}
                onChange={(event) => setText(event.target.value)}
            />
            <Button
                style={{marginTop: "1%"}}
                size="mini"
                type='submit'
                id='form-button-control'
            >Добавить</Button>
        </Form>
    );
};

export default CommentForm
