import React from 'react'
import {useAuth} from '../../hooks/useAuth'
import ModeratorsHeader from './ModeratorsHeader'
import UsersHeader from './UsersHeader'
import UnloggedHeader from './UnloggedHeader'

const HeaderBlock = () => {
    const {isAuth} = useAuth()
    const userStatus = localStorage.getItem("status")

    return isAuth ? (
        userStatus === "moderator" ?
            <ModeratorsHeader/> : <UsersHeader/>
    ) : <UnloggedHeader/>
}

export default HeaderBlock
