import React, {useState} from 'react'
import {Segment} from 'semantic-ui-react'
import {useParams} from 'react-router-dom';
import ButtonClose from './Buttons/ButtonClose'
import {useNavigate} from 'react-router-dom'
import IFrame from './IFrame'


const ModalPDF = () => {
    const navigate = useNavigate()
    const {filename} = useParams();
    const [open, setOpen] = useState(true);
    const handleClose= () => {setOpen(false); navigate('/user/docs')}
    return (
        <Segment
            open={open}
            size='fullscreen'>
            <ButtonClose handleClose={handleClose}/>
            <IFrame filename={filename}/>
        </Segment>
    )
}
export default ModalPDF
