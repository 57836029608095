const CardLinks = () => {
    return (
        <ul type="circle" id="up">
            <li><a href="#main">Текст поправки</a></li>
            <li><a href="#supplementary">Пояснительная записка</a></li>
            <li><a href="#finance">Финансовое обоснование</a></li>
            <li><a href="#revocation">Какие законы или другие НПА потребуется
                изменить или отменить</a></li>
            <li><a href="#codex">Ссылки на Собрание Законодательства РФ</a></li>
        </ul>
    )
}
export default CardLinks
