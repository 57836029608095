import React from 'react'
import {Button, Icon} from 'semantic-ui-react'
import './ButtonClose.css'

const ButtonClose = (props) => {
    const {handleClose} = props
    return (
        <Button onClick={handleClose}
                className="buttonClose">
            <Icon name='long arrow alternate left'
                  color="orange"/>
        </Button>
    )
}
export default ButtonClose
