import React from 'react';
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {addUserContact} from '../../redux/reducers/postContacttSlise'
// import AnonCheckbox from './AnonCheckbox'
import {Segment, Form, Button, Modal, Icon, Container} from 'semantic-ui-react'

const NewUserFormJSX = ({setSocialLink, coverLetterTouched, setCoverLetterValid,
                            onCoverLetterChange, contactTouched,
                            setContactIsValid, onContactChange,setCoverLetterTouched, coverLetter,
                            setContactTouched, contact, socialLink,handleOpen, handleClose, isCoverLetterValid,
                            isContactValid, open
                        }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const coverLetterRequired = true;
    const contactRequired = true;

    const onSocialLinkChange = (event) => {
        const value = event.target.value
        setSocialLink(value)
    }

    //validate functions
    const handleCoverLetterValidation = (event) => {
        const value = event.target.value;
        if ((coverLetterRequired && value.length === 0 && coverLetterTouched)) {
            setCoverLetterValid(false);
        } else {
            setCoverLetterValid(true);
        }
        onCoverLetterChange(value);
    };

    const handleContactValidation = (event) => {
        const value = event.target.value;
        if ((contactRequired && value.length === 0 && contactTouched)) {
            setContactIsValid(false);
        } else {
            setContactIsValid(true);
        }
        onContactChange(value);
    };
   //
   //onBlur functions
    const handleCoverLetterBlur = () => {
        if (!coverLetterTouched) {
            setCoverLetterTouched(true);
        }
        if (coverLetterRequired && coverLetter?.trim() === "") {
            setCoverLetterValid(false);
        }
    };

    const handleContactBlur = () => {
        if (!contactTouched) {
            setContactTouched(true);
        }
        if (contactRequired && contact?.trim() === "") {
            setContactIsValid(false);
        }
    };

    const onSubmit = () => {
        dispatch(addUserContact(
            coverLetter,
            contact,
            socialLink
        ))
            .then(() => {
                handleOpen()
            })
            .catch((error) => {
                alert("Извините, произошла ошибка")
            });
    }
    return (
        <Segment basic className="basicSegment">
            <Form style={{width: "100%"}}
                  onSubmit={onSubmit}>
                <Container>
                    <div style={{marginTop: "10px"}}>
                        <Form.TextArea
                            name='coverLetter'
                            maxlength="1000"
                            label='Напишите о себе (не более 1000 символов)'
                            placeholder='Напишите о себе'
                            onChange={handleCoverLetterValidation}
                            value={coverLetter}
                            onBlur={handleCoverLetterBlur}
                        />
                        {!isCoverLetterValid && coverLetterTouched &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
                            Пожалуйста, заполните это поле.</div>}
                    </div>
                    <Form.Group widths='equal'>
                        <Form.TextArea
                            id='form-input-control-contact'
                            maxlength="200"
                            label='Напишите, как с вами связаться'
                            placeholder='Данные для связи'
                            onChange={handleContactValidation}
                            value={contact}
                            onBlur={handleContactBlur}
                        />
                        {!isContactValid && contactTouched &&
                        <div className="ui left pointing tiny prompt label"
                             style={{height: "45px"}}
                             role="alert"
                             aria-atomic="true">
                            Пожалуйста, заполните это поле.</div>}
                        <Form.TextArea
                            id='form-input-control-social-link'
                            maxlength="100"
                            label='Соцсеть'
                            placeholder='Ссылка на соцсеть'
                            onChange={onSocialLinkChange}
                            value={socialLink}
                        />
                    </Form.Group>
                    {/*<div>*/}
                    {/*    <AnonCheckbox/>*/}
                    {/*</div>*/}
                    <Button
                        style={{marginTop: "1%"}}
                        type='submit'
                        id='form-button-control'
                        disabled={!isCoverLetterValid || !isContactValid}
                    >Отправить</Button>
                </Container>
                <Modal
                    open={open}
                    basic size='mini'>
                    <Button
                        style={{width: "250px"}}
                        onClick={() => {
                            handleClose();
                            navigate(`/user/profile`)
                        }}>
                        Данные отправлены
                    </Button>
                    <Button
                        style={{marginTop: "1%", width: "250px"}}
                        onClick={() => {
                            handleClose();
                            navigate(`/user/profile`)
                        }}>
                        Закрыть
                        <Icon name='close'
                              color="orange"
                              size='big'
                              style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px"
                              }}/>
                    </Button>
                </Modal>
            </Form>
        </Segment>
    );
};

export default NewUserFormJSX;

