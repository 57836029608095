import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {instance} from '../../api/api'


export const getRequests = createAsyncThunk(
    'requests/getRequests',
    async (reqStatus) => {
        const response = await
            instance.get(`/requests/${reqStatus}`)
        return response.data
    }
);

export const getMyRequests = createAsyncThunk(
    'myRequests/getMyRequests',
    async (reqStatus) => {
        const response = await
            instance.get(`/requests/my`);
        return response.data
    }
);

const initialState = {
    requests: [],
    status: 'idle',
    error: ''
}

const getRealmRequestsSlice = createSlice({
    name: 'requests',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getRequests.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getRequests.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.requests = action.payload;
                state.error = ""
            })
            .addCase(getRequests.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getMyRequests.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getMyRequests.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.requests = action.payload;
                state.error = ""
            })
            .addCase(getMyRequests.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export default getRealmRequestsSlice.reducer;
