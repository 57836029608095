import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {instance} from '../../api/api'


export const fetchRealms = createAsyncThunk(
    'realms/fetchRealms',
    async (id) => {
        const response = await
            instance.get(`/realms/${id}`)
        return response.data
    }
);

const initialState = {
    realmData: {},
    status: 'idle',
    error: ''
}

const getRealmsSlice = createSlice({
    name: 'realms',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchRealms.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(fetchRealms.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.realmData = action.payload;
                state.error = ""
            })
            .addCase(fetchRealms.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

    }
});

export default getRealmsSlice.reducer;
