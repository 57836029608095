import React, {useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {Icon, Label, Message} from 'semantic-ui-react'
import parse from 'html-react-parser';
import {fetchPreparedAmendments} from '../../../redux/reducers/getPreparedAmendmentsSlise'
import {formatDate, sortByDate} from '../../../functions/date'

const PreparedAmendmentsText = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const blockRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        dispatch(fetchPreparedAmendments())
    }, [dispatch])

    const preparedAmendments = useSelector(state => state.preparedAmendmentsReducer.preparedAmendments)

    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    useEffect(() => {
        function handleScroll() {
            if (isElementInViewport(blockRef.current)) {
                setIsHovered(true);
            } else {
                setIsHovered(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const sortedAmendments = [...preparedAmendments].sort(sortByDate)

    return (sortedAmendments.map((it, index) => {
            const realmName = it.realm?.name
            const documentName = it.realm?.document.name
            const mainText = it.mainText.slice(0, 200)
            const parsedText = parse(mainText || "")

            const formattedDate = formatDate(it.updateDate)

            return <Message
                key={index}
                color="olive"
                style={{
                    fontSize: "20px",
                    overflowX: "auto",
                    color: "black"
                }}
                onClick={() => {
                    navigate(`/amendment/${it.id}`)
                }} className={isHovered ? "hoveredSegment" : "hoverSegment"} ref={blockRef}>
                <div>
                    <Label key={`${it.id}6`} size="small" color="blue" inverted>
                        {realmName}
                    </Label>
                    <Label key={`${it.id}7`} size="small" color="blue">
                        {documentName}
                    </Label>
                    <div style={{marginTop: "1rem"}}><b>{index + 1}) Текст поправки:</b></div>
                    <div key={`${it.id}`}>{parsedText}</div>
                </div>

                <div>
                    <div style={{fontSize: "15px"}}>
                        <Icon name='calendar alternate outline'/><span>{formattedDate} </span>
                        <Icon name='user'/><span>{it.lawyer.name} </span>
                    </div>
                </div>
            </Message>
        })

    )
}
export default PreparedAmendmentsText
