import {Container} from 'semantic-ui-react';


const MainText = () => {
    return (
        <Container style={{marginTop: "2rem", fontSize: "16px", textAlign: 'justify'}}>
            <h2>О проекте</h2>

            <p>Авторы идеи - Владимир Ашурков, бывший исполнительный директор ФБК и Михаил Беньяш, бывший краснодарский
                адвокат и иностранный агент, которые в 2015-2016 годах занимались разработкой ряда положений программы
                президентской программы Алексея Навального. Название проекту дал Борис Акунин.</p>

            <p>Алексей Навальный даже упомянул о проекте “Санация Права” в ходе интервью у Юрия Дудя “как о единственном
                конструктивном предложении ФБК”. С тех пор в России произошел ряд неких событий, приведших к консервации
                проекта.</p>

            <p>В 2023 году, Михаил Беньяш с командой единомышленников юристов и программистов, перезапустил проект в новом
                формате. Сейчас он не является частью системы ФБК или штабов Алексея Навального и не финансируется ими.</p>

            <p>Этот проект - проект гражданского общества России не зависимый ни от каких общественных движений, партий,
                фондов или лидеров общественного мнения.</p>

            <p> Проект направлен на выполнение общей задачи той части жителей России, которые не согласны с тем, что сейчас
                происходит в России, т.е. ее большей части. Предполагается, что и решать эту задачу мы будем совместно.
                <b> Объединяя не оппозицию, а усилия</b> юристов и экспертов из разных, в том числе не всегда дружески настроенных
                друг к другу команд.</p>

            <h3>Почему 24 сентября 2011 года и другие вопросы</h3>

            <p>Проект исследует искажения закона, возникшие лишь после 24 сентября 2011г. Почему эта дата?</p>

            <p>24 сентября 2011 года является ключевой датой в Российской истории и истории российского права.</p>

            <p>В этот день действующий президент Медведев объявил о своем отказе от выдвижения на выборы президента
                России на второй срок, а бывший президент Владимир Путин сообщил о своем намерении выдвинуть свою
                кандидатуру на выборы президента в третий раз, чтобы затем сделать это в четвертый и скоро уже в пятый
                раз подряд. С этого дня, относительно развивающееся российское право стало разворачиваться назад, к
                авторитарному Советскому прошлому, в законы стали вносить репрессивные нормы одну за другой и именно в
                этот период Государственная дума получила свое уничижительное прозвище “Бешеный принтер”.</p>

            <p>У нас нет ни ресурсов, ни желания, ни морального права пересматривать весь объем российского закона,
            выполняя ту работу от которой самоустранился Конституционный суд.</p>
            <p>Поэтому мы поставили себе границу вмешательства - 24 сентября 2011 года.
            Мы сделаем исключение лишь для одной единственной нормы принятой 08 декабря 2003 года и об этом обязательно
            напишем отдельно.</p>

            <p>Системное противодействие работе “бешеного принтера” должно быть.
            Такое, чтобы все крики протеста, разгромные статьи экспертов, негодующие комментарии в фэйсбуке и
            остроумные, но не всегда приличные реплаи в твиттере, превратились в что-то конкретное, цельное и
            работоспособное, а кипучая деятельность коллективного разума стала систематизированной.</p>

            <p>При действующем политическом режиме тот законопроект, который мы разрабатываем, принят быть не может. Значит
            он будет принят после смены политического режима. И этот проект лучше всего сделать экспертам гражданского
            общества и сделать заранее, потому что птенцам гнезда Володина, такую работу поручать было бы крайне
            опрометчиво.</p>

            <h3>Один из самых часто задаваемых вопросов: а почему бы вообще все не взять и <s>поделить</s> отменить до того самого
            2011 года, о котором вы говорите?</h3>

            <p>Технически нечто подобное, наверное, сделать возможно, но было бы большой безответственностью, поскольку с
            2011 года принято колоссальное количество законов, подзаконных актов, судебных постановлений, заключено
            такое количество сделок которое невозможно подсчитать, сотни тысяч людей были осуждены и отбыли наказание.
            При этом далеко не все законы принятые “бешеным принтером” были безоговорочно плохие и бездумная отмена
            вообще всех норм, включая адекватные, будет являться грубым вмешательством в судьбы миллионов людей и
            принесет гораздо больше зла, чем существование норм неадекватных.</p>

            <p>Есть такой правовой принцип res judicata - принцип правовой определенности. Он слишком часто нарушается
            российскими властями, что привело к извращению российского права. Мы так поступать не будем.</p>

            <p>Самым простым возражением на этот вопрос является пример в виде законов о федеральном бюджете. Эти законы
            “отмотать назад” точно невозможно и категорически не нужно. То же самое можно сказать о гражданском
            законодательстве, множестве малоизвестных отраслевых законов которые госдума крутила и вертела, как ей
            только вздумается, иногда извращая, а иногда и даже улучшая.</p>

            <p>Поэтому единственный реалистичный способ устранить негативную работу госдумы с 2011 года - это в ручном
            режиме изыскать каждый деструктивный закон, обосновать его вредность и подготовить обоснованный документ для
            его отмены, который останется только подписать субъекту законодательной инициативы.</p>

        <h3>Почему недостаточно просто все “плохие” законы переписать и чтобы потом отменить? Зачем морока с
        “пояснительными записками”?</h3>

            <p>В отличие от госдумы и администрации президента, у гражданского общества нет никаких иных легальных
            инструментов для влияния на происходящее в России, кроме слова, сказанного вслух, и логики.
            Любое немотивированное и не основанное на понятном общественном интересе решение, вызовет лишь недоверие и
            вполне обоснованные упреки.</p>

            <p>Мы должны именно убедить общество в необходимости того, что подготовленный нами документ нуждается в
            принятии. Это не политическая программа определенной группы, дескать “мы придем к власти и все отменим”.
            Нет, это убеждение. Чтобы убедить в том числе и тех, кто придет к власти в России и чтобы уже новая власть
            принимала подготовленные гражданским обществом конкретные решения.</p>

        <p>Мы не претендуем на монополию правоты. Если в своих текстах мы неправы и в чем-то ошибаемся - напишите и мы
            обсудим. Адекватная критика лишь повышает качество юридической работы.</p>

        <h3>Зачем морока с отдельным сервисом? Не проще ли все сделать в обычном гугл-доке?</h3>

            <p>Мы думали об этом. Изначально с гуглдока все и начиналось. До сих пор подготовка многих текстов ведется в
            нем.</p>

            <p>Но поскольку мы пишем очень объемный документ, к которому повышенные требования в юридической технике,
            довольно подробно расписанные в соответствующем регламенте государственной думы, то чтобы сохранить логику
            текста, возможность навигации в нем и редактирования без сбоя уже существующей структуры, нам показалось
            разумным создать отдельный электронный инструмент. Мы рассматривали возможность работы с уже готовыми
            программными продуктами, но в итоге написать свое с нуля оказалось проще.</p>

        </Container>
    )
}
export default MainText
