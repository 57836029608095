import React from 'react'
import RealmEditor from './RealmEditor'
import {useParams} from 'react-router-dom';
import {addNewRealm} from '../../../redux/reducers/postRealmSlise'

const AddRealm = () => {
    const {docId} = useParams();
    const initialValues = {
        id: docId,
        name: "",
        description: "",
        document: {}
    }
    return (
        <RealmEditor
            title="Добавление новой области:"
            initialValues={initialValues}
            action={addNewRealm}
            message="создана"
            realmId=''
        />
    )
}
export default AddRealm
