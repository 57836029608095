import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {instance} from '../../api/api'


export const fetchMyAmendments = createAsyncThunk(
    'myAmendments/fetchMyAmendments',
    async () => {
        const response = await
            instance.get(`/amendments/my`);
        return response.data
    }
);

const initialState = {
    myAmendments: [],
    status: 'idle',
    error: ''
}

const getMyAmendmentsSlice = createSlice({
    name: 'myAmendments',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchMyAmendments.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(fetchMyAmendments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.myAmendments = action.payload;
                state.error = ""
            })
            .addCase(fetchMyAmendments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

    }
});

export default getMyAmendmentsSlice.reducer;
