import React from 'react'
import {Segment} from 'semantic-ui-react'
import regImage from '../images/registration.png'
import diplomImage from '../images/diplom.png'
import proposeAmendImage from '../images/proposeAmend.png'
import threeSectionsImage from '../images/3sections.png'
import lawsImage from '../images/laws.png'
import accessToLawImage from '../images/accessToLaw.png'
import myRequestsImage from '../images/myRequests.png'
import createAmendmentImage from '../images/createAmendment.png'
import amendmentsListImage from '../images/amendmentsList.png'
import oneAmendmentImage from '../images/oneAmendment.png'

const Manual = () => {

    const style = {marginTop: "1rem"}
    const imgStyle = {maxWidth: "100%", marginTop: "1rem"}
    return (
        <Segment style={{fontSize: "16px", width: "90%"}}>
            <h3>Инструкция по работе с конструктором</h3>

            <p>Конструктор представляет из себя многопользовательский электронный сервис для совместной подготовки
                законопроекта, которым из российского законодательства будут исключены нормы авторитарного и цензурного
                характера (далее по тексту — «деструктивные поправки»), которые были приняты российским парламентом с 24
                сентября 2011 года — дня, когда Владимир Путин официально объявил о своём намерении баллотироваться на
                третий президентский срок.
            </p>
            <p>То есть конструктор направлен на то, чтобы точечно откатить российской закон до «последней удачной
                сохранённой версии». Сам конечный документ изготавливается в полном соответствии с принятой в российском
                законотворчестве юридической техникой. Ответственность задачи вызвала необходимость в строгих правилах.
            </p>

            <p>Регистрируясь и верифицируясь на сайте, пользователь отказывается от прав на результат своей
                интеллектуальной деятельности. Результаты работы по подготовке законопроекта являются открытыми и
                доступными в использовании каждому желающему без каких-либо ограничений.
            </p>

            <h3>Регистрация и начало работы</h3>
            <p>Регистрация на сайте осуществляется через аккаунт Google. То есть для того чтобы войти на сайт, вам
                необходимо иметь свою гугл-почту.
            </p>
            <img src={regImage} alt="registration" style={imgStyle}/>
            <p style={style}>Потом мы попросим вас заполнить следующую форму:</p>
            <img style={imgStyle} src={diplomImage} alt="diplom"/>
            <p style={style}>Нам необходимо знать, кто вы, и убедиться, что вы обладаете достаточным опытом,
                квалификацией, а также
                что вы действительно тот, за кого себя выдаёте.
            </p>
            <p>В век спама и провокаций это, к сожалению, вынужденная предосторожность.</p>
            <p>Через некоторое время обновите страницу — и если ваша заявка одобрена, то откроется меню слева.
            </p>
            <p>Чтобы предложить какую-нибудь поправку, надо зайти в раздел «Предложить поправки».</p>
            <img style={imgStyle} src={proposeAmendImage} alt="proposeAmend"/>
            <p style={style}>Откроется страничка с тремя разделами: «Поправки в Федеральные законы», «Поправки в
                Федеральные
                конституционные законы» и раздел, который закрыт, потому что в разработке.
            </p>
            <img style={imgStyle} src={threeSectionsImage} alt="threeSections"/>
            <p style={style}>Затем нажимайте «Открыть список» исправляемых ФЗ (или ФКЗ).</p>
            <p>Вы увидите все законы, находящиеся в работе, и у вас появится возможность дополнять уже существующие
                правки или комментировать существующие. Для этого надо нажать на кнопку «Открыть»
            </p>
            <img style={{marginTop: "1rem", width: "90%"}} src={lawsImage} alt="laws"/>
            <p style={style}>Далее нажимаете на зеленую кнопку и запрашиваете доступ к интересующему вас закону.</p>
            <img style={imgStyle} src={accessToLawImage} alt="laws"/>
            <p style={style}>Статус запроса будет виден в разделе «Мои запросы».</p>
            <img style={imgStyle} src={myRequestsImage} alt="myRequests"/>
            <p style={style}>После одобрения вы сможете зайти в раздел того законопроекта, с которым хотите работать, и
                инициировать
                поправку.
            </p>
            <img style={imgStyle} src={createAmendmentImage} alt="createAmendment"/>
            <p style={style}>Перед вами откроется форма, которую надо заполнить, соблюдая правила из второй части
                инструкции.</p>
            <p>После проверки поправка будет опубликована и войдёт в конечный документ. При наличии вопросов
                целесообразности правки модераторы сайта свяжутся с вами, чтобы непосредственно обсудить вопросы права.
                В случае если текст пояснительной записки не проработан, она может быть возвращена с предложением
                доработать.
            </p>
            <p>При деструктивном поведении участников может последовать бан.</p>
            <p>Работа неанонимная и в конечном документе будет отметка о вашем авторстве. Если вы по причинам
                безопасности хотите сохранить анонимность, то можете отдельно попросить опубликовать текст от лица
                проекта.
            </p>
            <p>Также у вас может появиться желание улучшить уже существующие поправки или высказать своё мнение об их
                избыточности. Тогда необходимо зайти в уже опубликованную поправку и написать свой комментарий к ней. Но
                по существу и без флуда.
            </p>
            <img style={imgStyle} src={amendmentsListImage} alt="amendmentsList"/>
            <img style={imgStyle} src={oneAmendmentImage} alt="oneAmendment"/>
            <h3>Правила заполнения формы</h3>
            <ol>
                <p>
                    <li>К работе допускаются только люди, имеющие высшее образование в сфере права, философии,
                        социологии и политологии, предъявившие диплом о высшем образовании и, если есть, учёной степени.
                        Вместе с дипломом необходимо направить короткое мотивационное письмо, а также дать ссылку на
                        свои соцсети, если есть.
                    </li>
                </p>

                <p>
                    <li>Работа с конструктором является добровольной, неанонимной и волонтерской. Принадлежность к
                        различным политическим группам не является основанием для отказа в доступе, но в случае низкой
                        квалификации или деструктивного поведения администрация может ограничить доступ к конструктору.
                    </li>
                </p>
                <p>
                    <li>Верифицированный пользователь получает доступ к личному кабинету, через который он может
                        направлять администрации сайта запрос на исправление конкретных норм российского федерального
                        или федерального конституционного закона, которые были искажены с 24 сентября 2011 года. <br/>
                        После получения доступа к личному кабинету пользователь именуется автором, пользователи с
                        полномочиями по верификации, блокировке и одобрению результатов работы именуются модераторами.
                    </li>
                </p>
                <p>
                    <li>После согласования запроса пользователь самостоятельно готовит четыре текста: (а) текст самого
                        законопроекта об исправлении нормы, (б) текст пояснительной записки, (в) текст
                        финансово-экономического обоснования и (г) текст с перечнем федеральных законов, подлежащих
                        признанию утратившими силу, приостановлению, изменению и/или принятию в связи с принятием новой
                        нормы. После изготовления тексты вставляются в соответствующие окна конструктора, после чего
                        отправляются на публикацию.
                    </li>
                </p>
                <p>
                    <li>После одобрения модератором подготовленные тексты входят в единый сводный документ в
                        соответствии с логикой формирования нормативного правового акта.
                    </li>
                </p>
                <p>
                    <li> В случае наличия недостатков в подготовленных текстах они могут быть возвращены на доработку.
                        Опечатки, орфографические или грамматические ошибки могут быть устранены модераторами
                        самостоятельно.
                    </li>
                </p>
                <div style={style}>
                    <li>Текст самого законопроекта состоит лишь либо в признании утратившим силу полностью, либо в
                        возвращении редакции к состоянию, которое было до внесения деструктивных поправок (пример):
                        <br/>
                        <p><i>(а) «Статью 128.1 — признать утратившей силу»;
                        </i></p>

                        <p><i>(б) «В статье 29: пункт 20 изложить в следующей редакции:
                        </i><br/></p>

                        <p><i>Кандидат, избирательное объединение, выдвинувшее список кандидатов, со дня представления в
                            избирательную комиссию документов для регистрации кандидата, списка кандидатов вправе
                            назначить одного члена этой избирательной комиссии с правом совещательного голоса, а в
                            случае регистрации кандидата, списка кандидатов — по одному члену избирательной комиссии с
                            правом совещательного голоса в каждую нижестоящую избирательную комиссию. Избирательное
                            объединение, выдвинувшее зарегистрированного кандидата (зарегистрированных кандидатов) по
                            одномандатному (многомандатному) избирательному округу, вправе назначить одного члена
                            вышестоящей (по отношению к избирательной комиссии, зарегистрировавшей кандидата
                            (кандидатов)) избирательной комиссии с правом совещательного голоса. Каждое избирательное
                            объединение может назначить в избирательную комиссию не более одного члена избирательной
                            комиссии с правом совещательного голоса».
                        </i></p>
                        <p><b>Какие-либо самостоятельные изменения в ранее принятую и ранее использованную редакцию
                            закона не допускаются.
                        </b></p>
                    </li>
                </div>
                <p style={style}>
                    <li>Единовременно автор не может работать более чем над одной статьёй закона.</li>
                </p>
                <p>
                    <li>При подготовке сложных текстов авторы могут объединяться в группы. Для этого модератор создает
                        тематический чат в мессенджере телеграмм или сигнал, в котором группа экспертов отдельно
                        обсуждает вопросы права.
                    </li>
                </p>

                <div style={style}>
                    <li><p>В случае если текст законопроекта направлен на изменение редакции нормы закона, а не его
                        полную отмену, то в пояснительной записке к нему в обязательном порядке приводится сам текст той
                        редакции закона, которая была до деструктивных изменений, текст деструктивной поправки, которую
                        предлагается изменить, полные указания реквизитов законов старой редакции и деструктивной
                        поправки.</p>
                        <p>Пояснительная записка пишется на русском языке и должна соответствовать современному уровню
                            юридической науки. Рекомендуется избегать обильного цитирования, избыточно объёмных и
                            сложных по структуре предложений и перегружать текст специальными терминами, при возможности
                            заменять специальные термины общепринятыми синонимами.
                        </p>
                        <p>Стиль пояснительной записки сугубо деловой.</p>
                        <p>Жаргонизмы, ирония или эмоционально окрашенные фразы не допускаются. Исключение составляет
                            цитирование из судебной практики или научной литературы. Пояснительная записка составляется
                            таким образом, чтобы её текст был доступен для понимания лицу, не обладающему специальными
                            знаниями.
                        </p>
                        <p>В случае если пояснительная записка большая по объёму, её рекомендуется разбивать на разделы.
                        </p>
                        <p>В пояснительной записке рекомендуется избегать общих рассуждений, не основанных на фактах,
                            положениях вышестоящих нормативных правовых актах, судебной практике или статистике. При
                            использовании какой-либо научной литературы, судебной практики или данных статистики
                            необходимо полностью и точно указать источник сведений, включая доменное имя, если источник
                            сведений — сайты судов, Росстат или Официальный интернет-портал правовой информации
                            http://pravo.gov.ru.
                        </p>
                        Ссылка на «Википедию» не допускается.
                    </li>
                </div>
            </ol>

        </Segment>
    )
}
export default Manual
