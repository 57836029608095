import React from 'react'
import {Button, Icon} from 'semantic-ui-react'


const AmendmentCancelButton = ({action}) => {
    return (
        <Button
            style={{marginTop: "1%", width: "250px"}}
            onClick={() => {
                action(false)
            }}>
            Отмена
            <Icon name='close'
                  color="orange"
                  size='big'
                  style={{
                      marginLeft: "40px",
                      marginBottom: "5px"
                  }}/>
        </Button>)

}
export default AmendmentCancelButton
