import React from 'react'
import {Modal} from 'semantic-ui-react'
import AmendmentMessageButton from '../../components/User/Documents/Buttons/AmendmentMessageButton'

const MessageModal = ({open, path, action, message, id, reload}) => {
    return <Modal
        open={open}
        basic size='mini'>
        <AmendmentMessageButton
            path={path}
            action={action}
            message={message}
            id={id}
            reload={reload}/>
    </Modal>
}

export default MessageModal

