import {createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api';

const initialState = {
    id: "",
    comment: ""
}

export const reworkAmendmentSlice = createSlice({
    name: 'reworkAmendment',
    initialState,
    reducers: {
        denyAmendment: (state, action) => {
            return {
                ...state,
                id: action.payload.id,
                comment: action.payload.comment
            };
        },
        revokeAmendment: (state, action) => {
            return {
                ...state,
                id: action.payload.id,
                comment: action.payload.comment
            }
        }
    }
});

export const {denyAmendment, revokeAmendment} = reworkAmendmentSlice.actions;

export const denyNewAmendment = (id, comment) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/amendment/deny/${id}`, {
            comment
        }).then((response) => {
            dispatch(denyAmendment({id, comment}));
            resolve(response);
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export const revokeNewAmendment = (id, comment) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/amendment/revoke/${id}`, {
            comment
        }).then((response) => {
            dispatch(revokeAmendment({id}));
            resolve(response);
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export default reworkAmendmentSlice.reducer;
