import React from 'react'

const IFrame = ({filename}) => {
    const PDF = `https://api.sanatsia.com/storage/${filename}`
    return (
        <div>
                <iframe
                    title="pdf-preview"
                    type="application/pdf"
                    src={PDF}
                    width="100%"
                    height="800px">
                </iframe>
        </div>
    )
}
export default IFrame
