import {createSlice} from '@reduxjs/toolkit';
import axios from 'axios'

export const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState: {
        isLoading: false,
        error: null,
        success: false
    },
    reducers: {
        uploadFileStart: (state) => {
            state.isLoading = true;
            state.error = null;
            state.success = false;
        },
        uploadFileSuccess: (state) => {
            state.isLoading = false;
            state.error = null;
            state.success = true;
        },
        uploadFileError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            state.success = false;
        },
        clearUploadStatus: (state) => {
            state.isLoading = false;
            state.error = null;
            state.success = false;
        },
    },
});

export const {uploadFileStart, uploadFileSuccess, uploadFileError, clearUploadStatus} = fileUploadSlice.actions;

export const uploadFile = (diploma) => async (dispatch) => {
    dispatch(uploadFileStart());

    const diplom = new FormData();
    diplom.append('diploma', diploma);

    try {
        const response = await axios.post('https://api.sanatsia.com/back/1.0.0/auth/upload', diplom, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(uploadFileSuccess(response));
    } catch (error) {
        dispatch(uploadFileError(error.message));
        alert("Призошла ошибка")
    }
};

export default fileUploadSlice.reducer;
