import React from 'react';
import {useDispatch} from 'react-redux'
import AmendmentCancelButton from '../Documents/Buttons/AmendmentCancelButton'
import AmendmentActionButton from '../Documents/Buttons/AmendmentActionButton'
import {deleteRealm} from '../../../redux/reducers/deleteRealmSlise'
import {Modal} from 'semantic-ui-react'

const RealmEditorActionModal = ({openModal, handleCloseModal, setOpenDel, realmId, realmName}) => {
    const dispatch = useDispatch()
    return (
        <Modal
            open={openModal}
            basic size='mini'>
            <AmendmentActionButton
                handler={() => {
                    dispatch(deleteRealm(realmId))
                        .then((response) => {
                            if (response.payload.status === 200) {
                                handleCloseModal(realmId)
                                setOpenDel(true)
                            } else {
                                alert(`Извините, произошла ошибка: ${response.error.message}`)
                            }
                        })
                        .catch((error) => {
                            alert(`Извините, произошла ошибка`)
                        });
                }}
                message={`Нажмите, чтобы удалить "${realmName}"`}/>
            <AmendmentCancelButton action={handleCloseModal}/>
        </Modal>
    );
};

export default RealmEditorActionModal;
