import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {getAccessToken} from './redux/reducers/accessSlice'
import {getUser} from './redux/reducers/userSlise'
import {useNavigate} from 'react-router-dom'

const Logging = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getAccessToken())
            .then(() => {
                dispatch(getUser())
            })
            .then(() => {
                    navigate('/getstatus')
            })
            .catch(error => {
                alert("Ошибка")
            });
        // eslint-disable-next-line
    }, [dispatch, navigate])
    return (
        <h1>Loading...</h1>
    )
}

export default Logging
