import {createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api';

const initialState = {
    comment_id: ""
}

export const acceptCommentSlice = createSlice({
    name: 'acceptComment',
    initialState,
    reducers: {
        acceptComment: (state, action) => {
            return {
                ...state,
                comment_id: action.payload.comment_id
            };
        }
    }
});

export const {acceptComment} = acceptCommentSlice.actions;

export const acceptNewComment = (comment_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/comment/accept/${comment_id}`).then((response) => {
            dispatch(acceptComment({comment_id}));
            resolve(response);
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export const revokeNewComment = (comment_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/comment/revoke/${comment_id}`).then((response) => {
            dispatch(acceptComment({comment_id}));
            resolve(response);
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export default acceptCommentSlice.reducer;
