import {createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api';

const initialState = {
    comments: [{
        id: "",
        commented: "",
        mainText: "",
        lawyer: {name: ""},
        createdDate: ""
    }],
};

export const commentAmendmentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        addComment: (state, action) => {
            const newComment = {
                id: action.payload.id,
                commented: action.payload.commented,
                mainText: action.payload.comment_text,
                lawyer: "",
                createdDate: new Date()
            };
            state.comments = [...state.comments, newComment];
        }
    },
});

export const {addComment, getComment} = commentAmendmentSlice.actions;

export const addNewComment = (id, commented, comment_text) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/amendment/comment/${id}`, {
            id: id,
            commented: commented,
            comment_text: comment_text
        })
            .then((response) => {
                dispatch(addComment({id, commented, comment_text}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};



export default commentAmendmentSlice.reducer;
