import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Table, Button, Icon, Segment, Image} from 'semantic-ui-react'
import {fetchLawyers} from '../../../redux/reducers/getLawyersSlise'
import {allowLawyer} from '../../../redux/reducers/manageLawyersSlise'
import {isElementInViewport} from '../../../functions/isElementInViewPort'
import CoverLetterModal from '../../Modals/CoverLetterModal'
import {iconStyle, widthAutoCell, diplomButtonStyle} from './styles'
import './Users.css'


const Banned = () => {
    const dispatch = useDispatch()
    const blockRef = useRef(null);
    const [banned, setBanned] = useState([])
    const [openCoverLetter, setOpenCoverLetter] = useState({})
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        dispatch(fetchLawyers())
    }, [dispatch])
    useEffect(() => {
        function handleScroll() {
            if (isElementInViewport(blockRef.current)) {
                setIsHovered(true);
            } else {
                setIsHovered(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const lawyers = useSelector(state => state.getLawyersReducer.lawyers)
    const bannedLawyers = lawyers.filter(it => it.status === "banned")

    const showPreloader = (itemId) => {
        if (banned.includes(itemId)) {
            setBanned(banned.filter(id => id !== itemId));
        } else {
            setBanned([...banned, itemId]);
        }
    }
    const handleOpenCoverLetter = (id) => {
        setOpenCoverLetter(prevState => ({
            ...prevState,
            [id]: true
        }));
    };

    const handleCloseCoverLetter = (id) => {
        setOpenCoverLetter(prevState => ({
            ...prevState,
            [id]: false
        }));
    };
    return <Segment basic className="segmentStyle">
        {!lawyers ?
            (<div className="preloader">
                <Icon loading name='spinner' size='massive'/>
                <div><h2>Loading...</h2></div>
            </div>) :
            (<Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><Icon name='check'
                                                size='large'
                                                style={iconStyle}/>
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Имя</Table.HeaderCell>
                        <Table.HeaderCell>E-mail</Table.HeaderCell>
                        <Table.HeaderCell>Сопроводительное письмо</Table.HeaderCell>
                        <Table.HeaderCell>Контакт</Table.HeaderCell>
                        <Table.HeaderCell>Соцсеть</Table.HeaderCell>
                        <Table.HeaderCell>Файл диплома</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {bannedLawyers.map((it, index) => {
                        return <><Table.Row key={index}>
                            <Table.Cell collapsing>
                                {!banned.includes(it.id) ?
                                    <Button inverted color='green'
                                            onClick={() => {
                                                showPreloader(it.id)
                                                dispatch(allowLawyer(it.id))
                                                    .then(() => {
                                                        dispatch(fetchLawyers())
                                                    })
                                            }}>Разбанить</Button> :
                                    (<Icon loading name='spinner' size='big'/>)
                                }
                            </Table.Cell>
                            <Table.Cell style={{width: "auto"}}>{it.avatar ?
                                (<Image circular src={it.avatar} avatar size='mini'/>)
                                : (<Image circular src='https://cdn-icons-png.flaticon.com/512/6596/6596121.png' avatar
                                          size='mini'/>)}
                            </Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.name}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.email}</Table.Cell>
                            <Table.Cell className={isHovered ? "hoveredCoverLetter" : "coverLetter"} ref={blockRef}
                                        onClick={() => {
                                            handleOpenCoverLetter(it.id)
                                        }}>
                                {it.coverLetter ? `${it.coverLetter.slice(0, 60)}...` : it.coverLetter}
                            </Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.contact}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.socialLink}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>
                                {it.professionalFile?
                                    <Button
                                        style={diplomButtonStyle}
                                        onClick={() => {
                                            window.open
                                            (`https://api.sanatsia.com/storage/diplomas/${it.professionalFile}`,
                                                '_blank')
                                        }}
                                    >Посмотреть диплом
                                    </Button>: null}
                            </Table.Cell>
                        </Table.Row>
                            <CoverLetterModal open={openCoverLetter[it.id] || false}
                                              action={() => handleCloseCoverLetter(it.id)}
                                              coverLetter={it.coverLetter}
                            />
                        </>
                    })}
                </Table.Body>
            </Table>)}
    </Segment>
}

export default Banned

