export const cardStyle = {
    height: "110px",
    boxShadow: "0 1px 2px 0 #3399ff",
    border: "1px solid #3399ff"
}

export const cardDescriptionStyle = {
    height: "60px",
    fontSize: "13px"
}

export const lineStyle = {
    boxShadow: "#3399ff",
    height: "50px",
    fontSize: "12px"
}
