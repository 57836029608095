import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Table, Button, Icon, Segment, Image} from 'semantic-ui-react'
import {fetchLawyers} from '../../../redux/reducers/getLawyersSlise'
import {allowLawyer, disallowLawyer} from '../../../redux/reducers/manageLawyersSlise'
import {iconStyle, buttonWidth, widthAutoCell, diplomButtonStyle} from './styles'
import './Users.css'


const NewUsers = () => {
    const dispatch = useDispatch()
    const [requests, setRequests] = useState([])
    useEffect(() => {
        dispatch(fetchLawyers())
    }, [dispatch])

    const lawyers = useSelector(state => state.getLawyersReducer.lawyers)
    const newLawyers = lawyers.filter(it => it.status === "new")
    const newLawyersWithoutCoverLetter = newLawyers.filter(it => !it.coverLetter)

    const sortedNewLawyersWithoutCoverLetter = newLawyersWithoutCoverLetter.sort((a, b) => {
        if (a.professionalFile
            && !b.professionalFile
        ) {
            return -1;
        } else if (!a.professionalFile
            && b.professionalFile
        ) {
            return 1;
        } else {
            return 0;
        }
    });

    const showPreloader = (itemId) => {
        if (requests.includes(itemId)) {
            setRequests(requests.filter(id => id !== itemId));
        } else {
            setRequests([...requests, itemId]);
        }
    }


    return <Segment basic className="segmentStyle">
        {!lawyers ? (
                <div className="preloader">
                    <Icon loading name='spinner' size='massive'/>
                    <div><h2>Loading...</h2></div>
                </div>) :
            (<Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><Icon name='check'
                                                size='large'
                                                style={iconStyle}/>
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Имя</Table.HeaderCell>
                        <Table.HeaderCell>E-mail</Table.HeaderCell>
                        <Table.HeaderCell>Контакт</Table.HeaderCell>
                        <Table.HeaderCell>Соцсеть</Table.HeaderCell>
                        <Table.HeaderCell>Файл диплома</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedNewLawyersWithoutCoverLetter.map((it, index) => {
                        // const formattedDate = formatDate(it.createdDate)
                        return <> <Table.Row key={index}>
                            <Table.Cell collapsing>
                                {!requests.includes(it.id) ?
                                    <><Button inverted color='green'
                                              style={buttonWidth}
                                              onClick={() => {
                                                  showPreloader(it.id)
                                                  dispatch(allowLawyer(it.id))
                                                      .then((response) => {
                                                          dispatch(fetchLawyers())
                                                      })
                                              }}>Принять</Button>
                                        <div>
                                            <Button inverted color='red'
                                                    style={buttonWidth}
                                                    onClick={() => {
                                                        showPreloader(it.id)
                                                        dispatch(disallowLawyer(it.id))
                                                            .then((response) => {
                                                                dispatch(fetchLawyers())
                                                            })
                                                    }}>Отказать</Button>
                                        </div>
                                    </> :
                                    (<Icon loading name='spinner' size='big'/>)
                                }
                            </Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.avatar ?
                                (<Image circular src={it.avatar} avatar size='mini'/>)
                                : (<Image circular src='https://cdn-icons-png.flaticon.com/512/6596/6596121.png' avatar
                                          size='mini'/>)}
                            </Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.name}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.email}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.contact}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.socialLink}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>
                                {it.professionalFile ?
                                    <Button
                                        style={diplomButtonStyle}
                                        onClick={() => {
                                            window.open
                                            (`https://api.sanatsia.com/storage/diplomas/${it.professionalFile}`,
                                                '_blank')
                                        }}
                                    >Файл диплома
                                    </Button> : null}
                            </Table.Cell>
                        </Table.Row>
                        </>
                    })}
                </Table.Body>
            </Table>)}
    </Segment>
}

export default NewUsers

