import React, {useState, useEffect} from 'react'
import {addNewAmendment} from '../../../redux/reducers/postAmendmentSlise'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {initialValues} from './validation'
import {modules} from './helpers'
import '../Documents/Buttons/ButtonClose.css'
import CreateAmendmentForm from './CreateAmendmentForm'

const AmendmentForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {docId, id} = useParams();
    const [amendmentId, setAmendmentId] = useState(null)
    const [open, setOpen] = useState(false);
    const [touched, setTouched] = useState(true)

    const addEvent = (func) => {
        document.addEventListener('mousedown', func);
    }
    const removeEvent = (func) => {
        document.removeEventListener('mousedown', func);
    }
    const regExp = /<p><br><\/p>(\s*<p><br><\/p>)+/g

    const oneNewLine = '<p><br></p>'


    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseForm = () => {
        navigate(`/user/docs/realm/${docId}/${id}`)
    };

    const handleClearForm = () => {
        formik.setValues({
            mainText: "",
            supplementaryText: "",
            financeText: "",
            revocationText: "",
            codex: ""
        });
        localStorage.removeItem("formValues");
    };
    const savedValues = JSON.parse(localStorage.getItem('formValues')) || initialValues;

    const validate = (values) => {
        let errors = {}

        if ((values.mainText.length === 0 && touched)
            || formik.values.mainText === "<p></p>"
            || formik.values.mainText === "<p><br></p>"
            || formik.values.mainText === "<p> </p>") {
            errors.mainText = "Пожалуйста, заполните это поле"
        }
        if (!values.codex) {
            errors.codex = "Пожалуйста, заполните это поле"
        }
        return errors
    }

    const formik = useFormik({
        initialValues: savedValues,

        onSubmit: (formData) => {
            dispatch(addNewAmendment(
                formData.id = id,
                formData.realm = id,
                formData.mainText,
                formData.supplementaryText,
                formData.financeText,
                formData.revocationText,
                formData.lawyer = {
                    id: "",
                    name: "",
                    email: "",
                    contact: null,
                    status: "new",
                    professionalFile: ""
                },
                formData.moderationComment = "",
                formData.moderationStatus = "",
                formData.updateDate = "",
                formData.codex
            )).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    handleClearForm()
                }
                setAmendmentId(response.data.id)
            }).then(() => {
                handleOpen()
            }).catch((error) => {
                alert("Извините, произошла ошибка добавления")
            });
        },
        validate,
    });

    useEffect(() => {
        localStorage.setItem('formValues', JSON.stringify(formik.values));
    }, [formik.values]);

    useEffect(() => {
        const handleOutsideClickMain = (e) => {
            const updatedMainText = formik.values.mainText.replace(regExp, oneNewLine);
            formik.setFieldValue('mainText', updatedMainText);
        };
        addEvent(handleOutsideClickMain)
        return () => {
            removeEvent(handleOutsideClickMain)
        };
        // eslint-disable-next-line
    }, [formik.values.mainText, formik.setFieldValue]);

    useEffect(() => {
        const handleOutsideClickSuppl = (e) => {
            const updatedSupplText = formik.values.supplementaryText.replace(regExp, oneNewLine);
            formik.setFieldValue('supplementaryText', updatedSupplText);
        };
        addEvent(handleOutsideClickSuppl)
        return () => {
            removeEvent(handleOutsideClickSuppl)
        };
        // eslint-disable-next-line
    }, [formik.values.supplementaryText, formik.setFieldValue]);

    useEffect(() => {
        const handleOutsideClickFinance = (e) => {
            const updatedFinanceText = formik.values.financeText.replace(regExp, oneNewLine);
            formik.setFieldValue('financeText', updatedFinanceText);
        };
        addEvent(handleOutsideClickFinance)
        return () => {
            removeEvent(handleOutsideClickFinance)
        };
        // eslint-disable-next-line
    }, [formik.values.financeText, formik.setFieldValue]);

    useEffect(() => {
        const handleOutsideClickRevocation = (e) => {
            const updatedRevocationText = formik.values.revocationText.replace(regExp, oneNewLine);
            formik.setFieldValue('revocationText', updatedRevocationText);
        };
        addEvent(handleOutsideClickRevocation)
        return () => {
            removeEvent(handleOutsideClickRevocation)
        };
        // eslint-disable-next-line
    }, [formik.values.revocationText, formik.setFieldValue]);

    useEffect(() => {
        const handleOutsideClickCodex = (e) => {
            const updatedCodex = formik.values.codex.replace(/(\n{3,})/g, "\n\n");
            formik.setFieldValue('codex', updatedCodex);
        };
        addEvent(handleOutsideClickCodex)
        return () => {
            removeEvent(handleOutsideClickCodex)
        };
        // eslint-disable-next-line
    }, [formik.values.codex, formik.setFieldValue]);

    /* set values */
    const handleContentChangeMain = (value) => {
        formik.setFieldValue("mainText", value);
        setTouched(true)
    };
    const handleContentChangeSupplementary = (value) => {
        formik.setFieldValue("supplementaryText", value);
    };
    const handleContentChangeFinance = (value) => {
        formik.setFieldValue("financeText", value);
    };
    const handleContentChangeRevocation = (value) => {
        formik.setFieldValue("revocationText", value);
    };

    const handleContentChangeCodex = (value) => {
        formik.setFieldValue("codex", value);
    };

    /* focus out */
    const handleContentFocusOutMain = () => {
        formik.setFieldTouched("mainText", true);
        setTouched(true)
    };

    return (
        <CreateAmendmentForm handleCloseForm={handleCloseForm} formik={formik} modules={modules}
                             handleContentChangeMain={handleContentChangeMain} open={open}
                             handleContentFocusOutMain={handleContentFocusOutMain}
                             handleContentChangeSupplementary={handleContentChangeSupplementary}
                             handleContentChangeFinance={handleContentChangeFinance}
                             handleContentChangeRevocation={handleContentChangeRevocation}
                             handleContentChangeCodex={handleContentChangeCodex}
                             handleClearForm={handleClearForm} handleClose={handleClose} amendmentId={amendmentId}
        />
    )
}

export default AmendmentForm


