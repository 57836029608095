import React, {useState} from 'react'
import {Segment, Icon, Button} from 'semantic-ui-react'
import {useDispatch, useSelector} from 'react-redux'
import {banLawyer} from '../../../../../redux/reducers/manageLawyersSlise'
import {acceptNewComment, revokeNewComment} from '../../../../../redux/reducers/acceptCommentSlise'
import {fetchComments} from '../../../../../redux/reducers/getCommentsSlise'
import {formatDate} from '../../../../../functions/date'
import WarningModal from '../../../../Modals/WarningModal'
import MessageModal from '../../../../Modals/MessageModal'
import './CommentForm.css'

const CommentList = ({comments, commented, amendment_id, lawyerId}) => {
    const dispatch = useDispatch()
    const [openBanMap, setOpenBanMap] = useState({});
    const [openBan, setOpenBan] = useState(false)
    const [acceptComments, setAcceptComments] = useState([])
    const [status, setStatus] = useState("done")
    const userStatus = localStorage.getItem('status')
    const userId = useSelector(state => state.userSlice.profile?.id)

    const showPreloader = (itemId) => {
        if (acceptComments.includes(itemId)) {
            setAcceptComments(acceptComments.filter(id => id !== itemId));
        } else {
            setAcceptComments([...acceptComments, itemId]);
        }
    }

    const handleOpenBanModal = (id) => {
        setOpenBanMap(prevState => ({
            ...prevState,
            [id]: true
        }));
    };

    const handleCloseBanModal = (id) => {
        setOpenBanMap(prevState => ({
            ...prevState,
            [id]: false
        }));
    };

    const handlerFunc = (id, callback) => {
        setStatus("loading")
        dispatch(banLawyer(id))
            .then((response) => {
                if (response.status === 200 ||
                    response.status === 201) {
                    handleCloseBanModal(id)
                    setOpenBan(true)
                    dispatch(fetchComments(amendment_id))
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("Произошла ошибка")
                }
            })
            .finally(() => {
                setStatus("done");
            });
    }
    if (!comments) {
        return null;
    }
    const filteredComments = comments.filter(it => it.commented === commented)
    return filteredComments.length > 0 ? (
        <div>
            {filteredComments.map((comment, index) => {
                const formattedDate = formatDate(comment.createdDate)
                return (<>
                        <Segment key={index}
                                 className={comment.contributor === 0 ? "comment" : "checkedComment"}>
                            <p>{comment.mainText}</p>
                            <p>Автор: {comment.lawyer.name}
                                {comment.contributor !== 0 &&
                                <Icon name='handshake outline'
                                      title="Автор поправки отметил этот комментарий как важный вклад в текст"
                                      style={{marginLeft: "1rem"}}/>}</p>
                            <p>Дата: {formattedDate}</p>
                            {!acceptComments.includes(comment.id) ?
                                (userId === lawyerId ?
                                    (comment.contributor === 0 ?
                                        <Button onClick={() => {
                                            showPreloader(comment.id)
                                            dispatch(acceptNewComment(comment.id))
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        dispatch(fetchComments(amendment_id))
                                                            .then((response) => {
                                                                setAcceptComments([]);
                                                            })
                                                    }
                                                })
                                        }} size="mini">
                                            Отметить как участника текста
                                        </Button> :
                                        <Button onClick={() => {
                                            showPreloader(comment.id)
                                            dispatch(revokeNewComment(comment.id))
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        dispatch(fetchComments(amendment_id))
                                                            .then((response) => {
                                                                setAcceptComments([])
                                                            })
                                                    }
                                                })
                                        }} size="mini">
                                            Убрать отметку как участника текста
                                        </Button>) : null)

                                : (<Icon loading name='spinner' size='big'/>)}

                            {userStatus === "moderator" && <Button onClick={() => {
                                handleOpenBanModal(comment.lawyer.id)
                            }} size="mini">Забанить автора
                            </Button>}
                        </Segment>
                        <WarningModal open={openBanMap[comment.lawyer.id] || false}
                                      handler={() => handlerFunc(comment.lawyer.id)}
                                      status={status}
                                      click={() => handleCloseBanModal(comment.lawyer.id)}
                                      message={`Нажмите, чтобы забанить юриста ${comment.lawyer.name} и удалить все его поправки`}/>
                        <MessageModal open={openBan}
                                      path={`/amendment/${amendment_id}`}
                                      action={setOpenBan}
                                      message="Юрист забанен"
                                      id={null}/>
                    </>
                )
            })}
        </div>
    ) : null
};

export default CommentList
