import React, {useState} from 'react';
import DashboardRoutes from '../../routes/userRoutes';
import EditRoutes from '../../routes/editRoutes'
import {Icon, Menu, Segment, Sidebar} from 'semantic-ui-react';
import MediaQuery from 'react-responsive';
import UserMenu from './SidebarMenu'
import {gradientStyle} from './styles'


const DashboardUser = () => {
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const handleSidebarHide = () => {
        setSidebarVisible(false);
    };

    const handleToggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    return (
        <div style={{height: '100vh'}}>
            <MediaQuery maxWidth={991}>
                <Menu style={{marginBottom: "0px"}}>
                    <Menu.Item onClick={handleToggleSidebar}>
                        <Icon color='orange' size='big' name="list"/>
                    </Menu.Item>
                </Menu>
            </MediaQuery>
            <Sidebar.Pushable as={Segment} style={{margin: "0px"}}>
                <MediaQuery minWidth={992}>
                    <Sidebar
                        as={Menu}
                        animation="push"
                        icon='labeled'
                        direction="left"
                        inverted
                        color='blue'
                        vertical
                        visible
                        width="thin"
                        style={gradientStyle}
                    >
                        <UserMenu/>
                    </Sidebar>
                </MediaQuery>
                <MediaQuery maxWidth={991}>
                    <Sidebar
                        as={Menu}
                        animation="push"
                        icon='labeled'
                        direction="left"
                        onHide={handleSidebarHide}
                        inverted
                        color='blue'
                        vertical
                        visible={sidebarVisible}
                        width="thin"
                        style={gradientStyle}
                    >
                        <UserMenu/>
                    </Sidebar>
                </MediaQuery>
                <Sidebar.Pusher>
                    <Segment basic style={{height: 'auto' }}>
                        <DashboardRoutes/>
                        <EditRoutes/>
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>
    );
};
export default DashboardUser;

