import React from 'react';
import {useNavigate} from 'react-router-dom'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./AmendmentsEditor.css";
import AmendmentEditorModal from './AmendmentsEditorModal'
import {Header, Segment, Form, Button, Container} from 'semantic-ui-react'

const AmendmentsEditorFormJSX = ({
                                     onSubmit, modules, handleValidation, mainText, handleBlur, handlePaste,
                                     isValid, touched, onSupplementaryTextChange, supplementaryText,
                                     onFinanceTextChange, financeText, onRevocationTextChange, revocationText,
                                     handleCodexValidation, codex, handleCodexBlur, isCodexValid, codexTouched,
                                     open, handleClose, id, amendmentId, setCodex, setMainText, setSupplementaryText,
                                     setFinanceText, setRevocationText, textareaRef, mainTextRef, supplTextRef,
                                     financeTextRef, revocationTextRef
                                 }) => {
    const navigate = useNavigate()
    return (
        <Segment basic className="basicSegment">
            <span>
            <Header as='h2' textAlign='center'>
    <Header.Content>
      Редактирование поправки:
    </Header.Content>
            </Header>
        </span>
            <Form style={{width: "100%"}}
                  onSubmit={onSubmit}>
                <Container>
                    <div style={{marginTop: "1%"}}><label>Текст поправки</label></div>
                    <ReactQuill
                        ref={mainTextRef}
                        contentEditable="true"
                        modules={modules}
                        placeholder='Текст поправки'
                        name="mainText"
                        onChange={handleValidation}
                        value={mainText}
                        onBlur={handleBlur}
                        onPaste={handlePaste}
                    />
                    {!isValid && touched &&
                    <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
                        Пожалуйста, заполните это поле.</div>}
                    <div style={{marginTop: "1%"}}><label>Пояснительная записка к поправке</label></div>
                    <ReactQuill
                        ref={supplTextRef}
                        contentEditable="true"
                        modules={modules}
                        placeholder='Текст пояснительной записки'
                        name="supplementaryText"
                        onChange={onSupplementaryTextChange}
                        value={supplementaryText}
                    />

                    <div style={{marginTop: "1%"}}><label style={{marginTop: "1%"}}>Напишите
                        финансово-экономическое
                        обоснование или оставьте поле пустым,
                        если изменения закона о Бюджете не требуется</label></div>
                    <ReactQuill
                        contentEditable="true"
                        ref={financeTextRef}
                        modules={modules}
                        placeholder='Финансово-экономическое обоснование'
                        name="financeText"
                        onChange={onFinanceTextChange}
                        value={financeText}
                    />

                    <div style={{marginTop: "1%"}}><label>Напишите, какие законы или иные НПА потребуется
                        изменить или отменить, или оставьте поле пустым</label></div>
                    <ReactQuill
                        contentEditable="true"
                        ref={revocationTextRef}
                        modules={modules}
                        placeholder='Перечень законов и иных НПА подлежащих отмене или изменению'
                        name="revocationText"
                        onChange={onRevocationTextChange}
                        value={revocationText}
                    />
                    <div style={{marginTop: "10px"}}>
                        <div class="field">
                            <label>Ссылки на Собрание Законодательства РФ</label>
                            <textarea name="codex"
                                      ref={textareaRef}
                                      placeholder="Ссылки на Собрание Законодательства РФ"
                                      rows="3"
                                      onChange={handleCodexValidation}
                                      value={codex}
                                      onBlur={handleCodexBlur}>

                            </textarea>
                        </div>
                        {!isCodexValid && codexTouched &&
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true">
                            Пожалуйста, заполните это поле.</div>}
                    </div>
                    <Button
                        style={{marginTop: "1%"}}
                        type='submit'
                        id='form-button-control'
                        disabled={!isValid || !isCodexValid}
                    >Сохранить</Button>
                    <Button
                        onClick={() => {
                            navigate(`/amendment/${amendmentId}`)
                        }}
                        style={{marginTop: "1%"}}
                    >Отмена</Button>
                </Container>
                <AmendmentEditorModal open={open} handleClose={handleClose} id={id}/>
            </Form>
        </Segment>
    );
};

export default AmendmentsEditorFormJSX;
