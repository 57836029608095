import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Segment, Table, Icon, Image} from 'semantic-ui-react'
import {getRequests} from '../../../redux/reducers/getRealmRequestsSlise'

const DisallawedEdits = () => {
    const dispatch = useDispatch()
    const status = "rejected"
    useEffect(() => {
        dispatch(getRequests(status))
    }, [dispatch])
    const requests = useSelector(state => state.getRealmRequestsSlice.requests)

    return <Segment basic style={{width: "90%"}}>
        {!requests ?
            (<div className="preloader">
                <Icon loading name='spinner' size='massive'/>
                <div><h2>Loading...</h2></div>
            </div>) :
            (<Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Имя</Table.HeaderCell>
                        <Table.HeaderCell>Область</Table.HeaderCell>
                        <Table.HeaderCell>Комментарий к запросу</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {requests.map((it, index) => {
                        return <Table.Row key={index}>
                            <Table.Cell collapsing/>
                            <Table.Cell style={{width: "60px"}}>{it.avatar ?
                                (<Image circular src={it.lawyer.avatar} avatar size='mini'/>)
                                : (<Image circular src='https://cdn-icons-png.flaticon.com/512/6596/6596121.png' avatar
                                          size='mini'/>)}
                            </Table.Cell>
                            <Table.Cell style={{width: "400px"}}>{it.lawyer.name}</Table.Cell>
                            <Table.Cell style={{width: "300px"}}>{it.realm.name}</Table.Cell>
                            <Table.Cell style={{width: "300px"}}>{it.comment}</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>)}
    </Segment>
}

export default DisallawedEdits

