import React from 'react'
import {useSelector} from 'react-redux'
import {Segment, Icon} from 'semantic-ui-react'
import PreparedAmendmentsText from './PreparedAmendmentText'

const PreparedAmendmentsList = () => {
    const preparedAmendments = useSelector(state => state.preparedAmendmentsReducer.preparedAmendments)

    return (
        <Segment basic className="basicSegment">
            <Segment>
                <b style={{fontSize: "18px"}}>Список поправок.</b>
            </Segment>
            <div style={{fontSize: "18px"}}> Количество поправок: {preparedAmendments.length}</div>
            <div text>
                {!preparedAmendments ?
                    <div className="preloader">
                        <Icon loading name='spinner' size='massive'/>
                        <h2>Loading...</h2>
                    </div> :
                    <PreparedAmendmentsText/>
                }
            </div>
        </Segment>
    )
}
export default PreparedAmendmentsList
