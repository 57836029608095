import axios from "axios";

export const instance = axios.create({
    withCredentials: true,
    baseURL: 'https://api.sanatsia.com/back/1.0.0/',
    headers: {'Content-type': 'application/json; charset=UTF-8'}
})

instance.interceptors.request.use(function(config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
});

export const amendmentsAPI = {
    add(realm_id, realm, mainText, supplementaryText,
        financeText, revocationText, lawyer,
        moderationComment, moderationStatus, updateDate, codex) {
        return instance.post(`/amendments/${realm_id}`, {
            amendment: {
                id: realm_id,
                realm: realm,
                mainText: mainText,
                supplementaryText: supplementaryText,
                financeText: financeText,
                revocationText: revocationText,
                lawyer: lawyer,
                moderationComment: moderationComment,
                moderationStatus: moderationStatus,
                updateDate: updateDate,
                codex: codex
            }
        })
    },
    edit(amendment_id, realm, mainText, supplementaryText,
         financeText, revocationText, lawyer,
         moderationComment, moderationStatus, updateDate, codex) {
        return instance.post(`/amendment/${amendment_id}`, {
            amendment: {
                id: amendment_id,
                realm: realm,
                mainText: mainText,
                supplementaryText: supplementaryText,
                financeText: financeText,
                revocationText: revocationText,
                lawyer: lawyer,
                moderationComment: moderationComment,
                moderationStatus: moderationStatus,
                updateDate: updateDate,
                codex: codex
            }
        })
    }
}

export const realmAPI = {
    add(document_id, name, description, startingText, codex, document) {
        return instance.post(`/realms/${document_id}`, {
            realm: {
                id: document_id,
                name: name,
                description: description,
                startingText: startingText,
                codex: codex,
                document: document
            }
        })
    },
    edit(realm_id, name, description, startingText, codex, document) {
        return instance.post(`/realm/${realm_id}`, {
            realm: {
                id: realm_id,
                name: name,
                description: description,
                startingText: startingText,
                codex: codex,
                document: document
            }
        })
    }
}

