import {createSlice} from '@reduxjs/toolkit';
import {realmAPI} from '../../api/api';

const initialState = {
        realmData: [
            {
                id: '',
                docId: '',
                name: '',
                description: '',
                startingText: '',
                codex: '',
                document: {}
            }
        ]
    }
;

export const editRealmSlice = createSlice({
    name: 'edit',
    initialState,
    reducers: {
        editRealm: (state, action) => {
            return {
                ...state,
                id: action.payload.id,
                docId: action.payload.docId,
                name: action.payload.name,
                description: action.payload.description,
                startingText: action.payload.startingText,
                codex: action.payload.codex,
                document: action.payload.document
            };
        }
    }
});

export const {editRealm} = editRealmSlice.actions;

export const editTheRealm = (id, docId, name, description, startingText, codex, document) => (dispatch) => {
    return new Promise((resolve, reject) => {
        realmAPI.edit(id, docId, name, description, startingText, codex, document)
            .then((response) => {
                dispatch(editRealm({id, docId, name, description, startingText, codex, document}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export default editRealmSlice.reducer;
