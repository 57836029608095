import React from 'react'
import {Button, Icon} from 'semantic-ui-react'


const AmendmentActionButton = ({handler, message}) => {
    return (
        <Button
            style={{marginTop: "1%", width: "250px"}}
            onClick={() => {
                handler()
            }}>
            {message}
            <Icon name='check'
                  color="orange"
                  size='big'
                  style={{
                      marginLeft: "20px",
                      marginBottom: "5px"
                  }}/>
        </Button>
    )

}
export default AmendmentActionButton
