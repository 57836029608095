import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Button, Header, Icon, Message, Label} from 'semantic-ui-react'
import parse from 'html-react-parser';
import {formatDate} from '../../../../functions/date'
import {statusFunc, colorFunc} from '../../../../functions/status'
import {style, amendmentStyle, draftAmendmentStyle, buttonStyle} from './styles'
import './AmendmentsList.css'

const AmendmentsListText = ({amendments, id, realm}) => {
    const navigate = useNavigate()
    const [expandedIds, setExpandedIds] = useState([]);

    const toggleExpand = (itemId) => {
        if (expandedIds.includes(itemId)) {
            setExpandedIds(expandedIds.filter(id => id !== itemId));
        } else {
            setExpandedIds([...expandedIds, itemId]);
        }
    }
    return (amendments.map((it, index) => {
            const mainText = it.mainText
            const parsedMainText = parse(mainText || "")
            const slisedMainText = mainText.slice(0, 500)
            const parsedSlisedText = parse(slisedMainText || "")
            const supplementaryText = it.supplementaryText
            const parsedSupplementaryText = parse(supplementaryText || "")
            const financeText = it.financeText
            const parsedFinanceText = parse(financeText || "")
            const revocationText = it.revocationText
            const parsedRevocationText = parse(revocationText || "")
            const formattedDate = formatDate(it.updateDate)
            const status = statusFunc(it.moderationStatus)
            const color = colorFunc(it.moderationStatus)
            const modStatus = it.moderationStatus
            return expandedIds.includes(it.id) ?
                <Message key={index}
                         style={modStatus === "draft" ?
                             draftAmendmentStyle :
                             amendmentStyle} color={color}>
                    <div style={{fontSize: "15px"}}>
                        <Icon name='calendar alternate outline'/><span key={`${it.id}6`}>{formattedDate} </span>
                        <Icon name='user'/><span key={`${it.id}4`}>{it.lawyer.name} </span>
                        <Label key={`${it.id}7`} color={color}>
                            {status}
                        </Label>
                        <Button
                            onClick={() => {
                                navigate(`/amendment/${it.id}`)
                            }}
                            className="triggerButton"
                            size='mini'
                            style={{marginBottom: "2px", backgroundColor: "#3399ff"}}
                            color="blue">
                            Перейти к поправке
                        </Button>
                    </div>
                    <div>
                        <div key={`${it.id}`}>{parsedMainText}</div>
                    </div>
                    <div>
                        <div style={style}><b>Пояснительная записка:</b></div>
                        <div key={`${it.id}1`}>{parsedSupplementaryText}</div>
                    </div>
                    <div style={style}><b>Финансовое обоснование:</b></div>
                    <div key={`${it.id}2`}>{parsedFinanceText}</div>
                    <div>
                        <div style={style}><b>Какие законы или другие НПА потребуется
                            изменить или отменить:</b></div>
                        <div key={`${it.id}3`}>{parsedRevocationText}</div>
                        <div/>
                        <div style={style} key={`${it.id}5`}>Комментарий модератора: {it.moderationComment}</div>
                    </div>
                    <Header as='h3'
                            textAlign='center'
                            color="blue"
                            style={{cursor: "pointer", marginTop: "1rem"}}
                            onClick={() => {
                                toggleExpand(it.id)
                            }}>
                        Cвернуть ⇧
                    </Header>
                </Message> :
                (<Message key={index}
                          style={modStatus === "draft" ?
                              draftAmendmentStyle :
                              amendmentStyle} color={color}>
                    <div>
                        <div style={{fontSize: "15px"}}>
                            <Icon name='calendar alternate outline'/>
                            <span key={`${it.id}6`}>{formattedDate} </span>
                            <Icon name='user'/>
                            <span key={`${it.id}4`}>{it.lawyer.name} </span>
                            <Label key={`${it.id}7`} color={color}>
                                {status}
                            </Label>
                            <Button
                                onClick={() => {
                                    navigate(`/amendment/${it.id}`)
                                }}
                                className="triggerButton"
                                color="blue"
                                size='mini'
                                style={buttonStyle}
                            >
                                Перейти к поправке
                            </Button>
                        </div>
                        <div key={`${it.id}`}>{parsedSlisedText}...</div>
                        <Header as='h3'
                                textAlign='center'
                                color="blue"
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    toggleExpand(it.id)
                                }}>
                            Развернуть ⇩
                        </Header>
                    </div>
                </Message>)
        })
    )
}
export default AmendmentsListText
