import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchDocuments = createAsyncThunk(
    'docs/fetchDocuments',
    async () => {
        const response = await
            await axios.get(`https://api.sanatsia.com/back/1.0.0/documents`)
        return response.data
    }
);

const initialState = {
    documents: [],
    status: 'idle',
    error: ''
}

const getDocsSlice = createSlice({
    name: 'docs',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchDocuments.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(fetchDocuments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.documents = action.payload;
                state.error = ""
            })
            .addCase(fetchDocuments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

    }
});

export default getDocsSlice.reducer;
