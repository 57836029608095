import React from 'react'
import {Menu, Icon} from 'semantic-ui-react'
import {NavLink} from 'react-router-dom'
import './Header.css'

const UsersHeader = () => {
    return <Menu stackable inverted color='blue'
                 style={{
                     marginBottom: "0",
                     backgroundColor: "#3399ff",
                     width: "100%"
                 }}>
        <NavLink to="/" style={{lineHeight: "11px"}}>
            <div className="logo">
                <img src="https://api.sanatsia.com/logo.svg"
                     alt="logo"
                     style={{width: "200px",
                         height: "68px"}}/>
            </div>
        </NavLink>
        <Menu.Menu position="right" style={{paddingRight: "1%"}}>
            {/*<Menu.Item as={NavLink} to={'/donate'}>*/}
            {/*    <Button color="red" size="mini">*/}
            {/*        <Icon name="bitcoin"/>*/}
            {/*        Помочь*/}
            {/*    </Button>*/}
            {/*</Menu.Item>*/}
            <Menu.Item as={NavLink} to={'/about'}>
                <Icon name="align left"/>
                О проекте
            </Menu.Item>
            <Menu.Item>
                <a href="https://api.sanatsia.com/back/1.0.0/auth/login">
                    <Icon name="sign-in"/>
                    Вoйти
                </a>
            </Menu.Item>
        </Menu.Menu>
    </Menu>
}

export default UsersHeader
