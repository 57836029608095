import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Segment, Icon} from 'semantic-ui-react'
import {getMyRequests} from '../../redux/reducers/getRealmRequestsSlise'
import MyRequestsTable from './MyRequestsTable'
import './UserPage.css'

const MyRequests = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    useEffect(() => {
        if (token) {
            dispatch(getMyRequests())
        } else {
            navigate('/')
        }
    }, [dispatch, navigate, token])
    const myRequests = useSelector(state => state.getRealmRequestsSlice.requests)
    // const status = useSelector(state => state.getRealmRequestsSlice.status)

    return (<Segment basic style={{width: "90%"}}>
        {!myRequests ?
            (<div className="preloader">
                <Icon loading name='spinner' size='massive'/>
                <div><h2>Loading...</h2></div>
            </div>) : <MyRequestsTable myRequests={myRequests}/>
        }
    </Segment>)
}

export default MyRequests

