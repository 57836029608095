import React from 'react'
import {Segment} from 'semantic-ui-react';


const MainText = () => {
    return (
        <Segment  style={{margin: "2rem 2rem 0 2rem", fontSize: "17px"}}>
            <p><b>Этот раздел сайта предназначен для экспертной группы и желающих к ним присоединиться.</b> </p>
            <p> Экспертная группа с помощью специального электронного сервиса готовит текст законопроектов,
                предназначенных для «очистки» российской системы права от авторитарных норм, появившихся с 24 сентября
                2011 г.
            </p>
            <p>Координирует работу экспертов краснодарский юрист Михаил Беньяш.</p>
            <p>Список законов ведётся в форме уже готового законопроекта, в любую минуту готового к публикации и
                регистрации в Государственной думе любым субъектом законодательной инициативы. На главной странице он
                выложен в форме веб-документа с оглавлением и активными гиперссылками. Для печати он автоматически
                генерируется в формате PDF.
            </p>
            <p>Поскольку список сразу составляется в форме уже готового законопроекта, то он является не только
                политической декларацией, но и документом, имеющим прямое прикладное значение.
            </p>
            <p>Проект является открытым, любые силы демократической направленности могут принимать участие в нем и/или
                использовать его наработки.
            </p>
            <p>Работа экспертной группы основана на нескольких правилах:
            </p>
            <ol>
                <p>
                    <li><b>Доступность участия.</b> Участником проекта может быть любое лицо, обладающее экспертными
                        познаниями в сфере правового регулирования, в которой с 24 сентября 2011 года были внесены
                        деструктивные изменения. Именно после принятого <a
                            target="_blank" rel="noopener noreferrer"
                            href="https://www.bbc.com/russian/russia/2011/09/110924_putin_medvedev_live">24 сентября
                            2011 года решения</a>, Государственная
                        дума получила название «Бешеный принтер»

                    </li>
                </p>

                <p>
                    <li><b>Открытость и доступность результатов работы.</b> Наработки проекта, со ссылкой на авторство,
                        могут
                        использовать все желающие, вне зависимости от партийной и идеологической принадлежности.
                    </li>
                </p>

                <p>
                    <li><b>Сдержанность.</b> В ходе проекта предлагается не разработка новых норм, а отмена существующих
                        репрессивных норм и возвращение к тем, которые были до их искажения деятельностью Госдумы с 24
                        сентября 2011 года.
                    </li>
                </p>
                <p>
                    <li><b>Ясность и обоснованность.</b> Все предложения по отмене той или иной нормы должны быть
                        умопостигаемы, понятны, логичны и основываться в первую очередь на правах и свободах человека.
                        Все пояснительные записки к законопроектам готовятся таким образом, чтобы быть понятными любому
                        лицу, даже не обладающему специальными знаниями.
                    </li>
                </p>

            </ol>
            <p> Для участия в проекте вы можете кликнуть на кнопку “Войти”.</p>
        </Segment>
    )
}
export default MainText
