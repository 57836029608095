import React from 'react'
import {Modal, Button} from 'semantic-ui-react'

const CoverLetterModal = ({open, action, coverLetter}) => {
    return <Modal
        open={open}
        basic size='small'>
        <div class="ui modal transition visible active">
            <Modal.Header>
                Сопроводительное письмо
            </Modal.Header>
            <Modal.Content style={{color: "black"}}>
                <p>{coverLetter}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={action}>Закрыть</Button>
            </Modal.Actions>
        </div>
    </Modal>
}

export default CoverLetterModal

