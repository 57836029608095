import React from 'react'
import {Button} from 'semantic-ui-react'

const ModeratorProposedButtons = ({setOpenAccept, handleDeny}) => {
    return (
        <>
            <Button
                onClick={() => {
                    setOpenAccept(true)
                }}
                className="triggerButton"
                size='mini'
                style={{marginBottom: "2px", backgroundColor: "#3399ff"}}
                color="blue">
                Принять
            </Button>
            <Button
                onClick={handleDeny}
                className="triggerButton"
                size='mini'
                style={{marginBottom: "2px", backgroundColor: "#3399ff"}}
                color="blue">
                Отказать
            </Button>
        </>
    )
}
export default ModeratorProposedButtons
