import React, {useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {Segment, Icon, Label, Message} from 'semantic-ui-react'
import parse from 'html-react-parser';
import {fetchMyAmendments} from '../../../../redux/reducers/getMyAmendmentsSlise'
import {formatDate, sortByDate} from '../../../../functions/date'
import {isElementInViewport} from '../../../../functions/isElementInViewPort'
import {colorFunc} from '../../../../functions/status'
import {amendmentStyle, draftAmendmentStyle, textHeaderStyle} from './styles'
import './MyAmendmentsList.css'

const MyAmendmentsList = () => {
    const token = localStorage.getItem("token")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const blockRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        if (token) {
            dispatch(fetchMyAmendments())
        } else {
            navigate('/')
        }
    }, [dispatch, navigate, token])

    const myAmendments = useSelector(state => state.myAmendmentsReducer.myAmendments)

    useEffect(() => {
        function handleScroll() {
            if (isElementInViewport(blockRef.current)) {
                setIsHovered(true);
            } else {
                setIsHovered(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const sortedAmendments = [...myAmendments].sort(sortByDate)
    const text = sortedAmendments.map((it, index) => {
        const realmName = it.realm?.name
        const documentName = it.realm?.document.name
        const mainText = it.mainText.slice(0, 200)
        const parsedText = parse(mainText || "")
        const formattedDate = formatDate(it.updateDate)
        const color = colorFunc(it.moderationStatus)
        const modStatus = it.moderationStatus

        return <Message key={index}
                        style={modStatus === "draft" ?
                            draftAmendmentStyle :
                            amendmentStyle}
                        color={color}
                        onClick={() => {
                            navigate(`/amendment/${it.id}`)
                        }} className={isHovered ? "hoveredSegment" : "hoverSegment"} ref={blockRef}>
            <div>
                <Label key={`${it.id}6`} size="small" color="blue">
                    {realmName}
                </Label>
                <Label key={`${it.id}7`} size="small" color="blue">
                    {documentName}
                </Label>
                <div style={textHeaderStyle}><b>{index + 1}) Текст поправки:</b></div>
                <div key={`${it.id}`}>{parsedText}</div>
            </div>
            <div style={{fontSize: "15px"}}>
                <Icon name='calendar alternate outline'/><span>{formattedDate} </span>
                <Icon name='user'/><span>{it.lawyer.name} </span>
            </div>
        </Message>
    })
    return (
        <Segment basic className="basicSegment">
            <Segment>
                <b style={{fontSize: "18px"}}>Список моих поправок.</b>
            </Segment>
            <div style={{fontSize: "18px"}}> Количество поправок: {myAmendments.length}</div>
            <div text>
                {!myAmendments ?
                    <div className="preloader">
                        <h2>Loading...</h2>
                    </div> :
                    (
                        <div>{text}</div>
                    )}
            </div>
        </Segment>
    )
}
export default MyAmendmentsList
