import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {instance} from '../../api/api'


export const fetchRealm = createAsyncThunk(
    'realm/fetchRealm',
    async (realm_id) => {
        const response = await
            instance.get(`/realm/${realm_id}`);
        return response.data
    }
);

const initialState = {
    realm: {},
    status: 'idle',
    error: ''
}

const getRealmSlice = createSlice({
    name: 'realm',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchRealm.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(fetchRealm.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.realm = action.payload;
                state.error = ""
            })
            .addCase(fetchRealm.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

    }
});

export default getRealmSlice.reducer;


