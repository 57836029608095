import React from 'react'
import {Routes, Route} from 'react-router'
import {Navigate} from 'react-router'
import MainPage from '../components/Main'
import DashboardUser from '../components/Dashboard/DashboardUser'
import AdminDashboard from '../components/Admin/AdminDashboard/AdminDashboard'
import AdminEditsDashboard from '../components/Admin/AdminDashboard/AdminEditsDashboard'
import ModalPDF from '../components/User/Documents/ModalPDF'
import Policy from '../policy/Policy'
import Terms from '../policy/Terms'
import About from '../documents/About'
import Donate from '../documents/Donate'
import Logging from '../Logging'
import GetStatus from '../GetStatus'


export const useRoutes = () => {
    const token = localStorage.getItem("token")
    const userStatus = localStorage.getItem("status")
    return (
        <div>
            <Routes>
                <Route exact path="/" element={<MainPage/>}/>
                <Route exact path="/policy" element={<Policy/>}/>
                <Route path="/logging" element={<Logging/>}/>
                <Route exact path="/terms" element={<Terms/>}/>
                <Route exact path="/about" element={<About/>}/>
                <Route exact path="/donate" element={<Donate/>}/>
                {token ? (
                    <>  <Route path="/user/profile" element={<DashboardUser/>}/>
                        <Route path="/getstatus" element={<GetStatus/>}/>
                        <Route exact path="user/manual" element={<DashboardUser/>}/>
                        {userStatus === "moderator" || userStatus === "active" ? (
                            <>  <Route path="/user/myrequests" element={<DashboardUser/>}/>
                                <Route path="/user/myamendments" element={<DashboardUser/>}/>
                                <Route path="/user/docs" element={<DashboardUser/>}/>
                                <Route path="/amendment/:id" element={<DashboardUser/>}/>
                                <Route path="/editamendment/:id" element={<DashboardUser/>}/>
                                <Route path="/user/docs/pdf/:filename" element={<ModalPDF/>}/>
                                <Route path="/user/docs/realms/:docId" element={<DashboardUser/>}/>
                                <Route path="/user/docs/realm/:docId/:id" element={<DashboardUser/>}/>
                                <Route path="/user/docs/realm/amendment/:docId/:id" element={<DashboardUser/>}/>
                            </>) : <Route path="*" element={<Navigate to="/user/profile"/>}/>}
                        {userStatus === "moderator" ? (
                            <>   <Route path="/dashboard/disallow" element={<AdminDashboard/>}/>
                                <Route path="/dashboard/new" element={<AdminDashboard/>}/>
                                <Route path="/dashboard/requests" element={<AdminDashboard/>}/>
                                <Route path="/dashboard/approved" element={<AdminDashboard/>}/>
                                <Route path="/dashboard/banned" element={<AdminDashboard/>}/>
                                <Route path="/editdashboard/requests" element={<AdminEditsDashboard/>}/>
                                <Route path="/editdashboard/approved" element={<AdminEditsDashboard/>}/>
                                <Route path="/editdashboard/disallow" element={<AdminEditsDashboard/>}/>
                                <Route path="/editdashboard/prepared" element={<AdminEditsDashboard/>}/>
                                <Route path="/addrealm/:docId" element={<DashboardUser/>}/>
                                <Route path="/editrealm/:docId/:id" element={<DashboardUser/>}/>
                            </>) : <Route path="*" element={<Navigate to="/user/profile"/>}/>
                        }
                    </>) : <Route path="*" element={<Navigate to="/"/>}/>
                }
            </Routes>
        </div>
    )

}
