import React from 'react'
import {Button, Icon, Modal} from 'semantic-ui-react'
import AmendmentActionButton from '../../components/User/Documents/Buttons/AmendmentActionButton'

const WarningModal = ({open, handler, click, message, status}) => {
    return <Modal
        open={open}
        basic size='mini'>
        {status === "loading" ?
            <h2>Loading...</h2> :
            <AmendmentActionButton
                handler={handler}
                message={message}/>}
        <Button
            style={{marginTop: "1%", width: "250px"}}
            onClick={click}>
            Отмена
            <Icon name='close'
                  color="orange"
                  size='big'
                  style={{
                      marginLeft: "40px",
                      marginBottom: "5px"
                  }}/>
        </Button>)
    </Modal>
}

export default WarningModal

