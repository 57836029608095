import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {fetchDocuments} from '../../../redux/reducers/getDocsSlise'
// import {fetchRealms} from '../../../redux/reducers/getRealmsSlice'
import {Header, Segment, List, Menu, Button, Icon} from 'semantic-ui-react'
import DocsMenu from './DocsMenu'
import {style} from './styles'
import './Docs.css'

const Docs = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    const documents = useSelector(state => state.docsReducer.documents)
    useEffect(() => {
        if (token) {
            dispatch(fetchDocuments())
        } else {
            navigate('/')
        }
    }, [dispatch, navigate, token])

    if (!documents) {
        return <Header as='h1'> Loading...</Header>;
    }

    return (
        <div>
            <Segment basic style={{marginBottom: "0px"}}>
                <Header as='h2'>
                    Документы:
                </Header>
            </Segment>
            {!documents ?
                (<div className="preloader">
                    <Icon loading name='spinner' size='massive'/>
                    <div><h2>Loading...</h2></div>
                </div>) :
                (
                    <Segment basic style={{marginTop: "0px"}} className="basicSegment">
                        {documents.map((it, index) => {
                            let title = ""
                            let type = it.type
                            if (type === "fz") {
                                title = "ФЗ"
                            } else if (type === "constitution") {
                                title = "статей"
                            } else if (type === "fkz") {
                                title = "ФКЗ"
                            }
                            return <div key={index}>
                                <List>
                                    <Segment style={{maxWidth: "100%"}}>
                                        <List.Item style={{maxWidth: "100%"}}>
                                            <List.Content>
                                                <Menu stackable borderless
                                                      style={{
                                                          boxShadow: "none",
                                                          border: "none",
                                                          maxWidth: "70%"
                                                      }}>
                                                    <Menu.Item style={{maxWidth: "40%"}}>
                                                        <List.Header as='h4'>
                                                            {it.name}
                                                        </List.Header>
                                                    </Menu.Item>
                                                    <Menu.Menu position="right">
                                                        <Button onClick={() => {
                                                            (navigate(`/user/docs/realms/${it.id}`))
                                                        }}
                                                                color="blue"
                                                                style={style}>
                                                            Oткрыть список исправляемых {title}
                                                        </Button>
                                                        <a href={`https://api.sanatsia.com/storage/${it.filename}`}
                                                           rel="noreferrer noopener"
                                                           target="_blank"
                                                           style={{
                                                               display: 'block',
                                                               textDecoration: 'none',
                                                               color: 'inherit'
                                                           }}>
                                                            <Button
                                                                //     onClick={() => {
                                                                //     dispatch(fetchRealms(it.id))
                                                                //     navigate(`/user/docs/pdf/${it.filename}`)
                                                                // }}
                                                                color="blue"
                                                                style={style}>
                                                                Открыть pdf
                                                                <Icon style={{marginLeft: "2%"}}
                                                                      name="file pdf outline"/>
                                                            </Button>
                                                        </a>
                                                    </Menu.Menu>
                                                </Menu>
                                                <DocsMenu
                                                    description={it.description}
                                                    publishedDate={it.updateDate}
                                                />
                                            </List.Content>
                                        </List.Item>
                                    </Segment>
                                </List>
                            </div>
                        })}
                    </Segment>
                )}
        </div>
    )
}

export default Docs

