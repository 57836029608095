import {useRoutes} from './routes/index'
import {useLocation} from "react-router-dom";
import Header from './components/Header/Header'


const AppContent = () => {
    const routes = useRoutes();
    const location = useLocation();
    return (
        <div>
            {location.pathname.startsWith("/logging") ?
                routes : (
                    <>
                        <Header/>
                        {routes}
                    </>)
            }
        </div>
    );
};

export default AppContent
