
export const validate = values => {
    let errors = {}
    if (!values.description) {
        errors.description = "Пожалуйста, заполните это поле"
    }
    if (!values.startingText) {
        errors.startingText = "Пожалуйста, заполните это поле"
    }
    if (!values.codex) {
        errors.codex = "Пожалуйста, заполните это поле"
    }
    return errors
}

