import React from 'react'
import CommentList from './CommentList'
import CommentForm from './CommentForm'
import {Segment} from 'semantic-ui-react'


const CommentBox = ({id, lawyerId, commented, comments}) => {

    return (
        <Segment>
            <CommentForm id={id} commented={commented}/>
            <h4>Комментарии</h4>
            <CommentList
                lawyerId={lawyerId}
                comments={comments}
                commented={commented}
                amendment_id={id}/>
        </Segment>
    );
};

export default CommentBox;
