import {createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api';

const initialState = {
    id: "",
    comment: ""
}

export const requestForRealmSlice = createSlice({
    name: 'requestForRealm',
    initialState,
    reducers: {
        requestRealm: (state, action) => {
            return {
                ...state,
                id: action.payload.id,
                comment: action.payload.comment
            };
        }
    }
});

export const {requestRealm} = requestForRealmSlice.actions;

export const requestForTheRealm = (id, comment) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/request/${id}`, {
            comment
        }).then((response) => {
            dispatch(requestRealm({id, comment}));
            resolve(response);
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export default requestForRealmSlice.reducer;
