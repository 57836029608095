import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom';
import ButtonClose from '../Buttons/ButtonClose'
import {useNavigate} from 'react-router-dom'
import {fetchRealms} from '../../../../redux/reducers/getRealmsSlice'
import {realmUp, realmDown} from '../../../../redux/reducers/realmUpDownSlise'
import {buttonStyle, greyButtonStyle, segmentStyle} from './styles'
import {handleTitle} from '../../../../functions/docType'
import {Segment, List, Menu, Button, Icon} from 'semantic-ui-react'
import './Realm.css'

const RealmsList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {docId} = useParams();
    useEffect(() => {
        dispatch(fetchRealms(docId))
    }, [dispatch, docId])
    const realmsData = useSelector(state => state.realmsReducer.realmData)
    const status = useSelector(state => state.realmsReducer.status)
    const realms = realmsData ? realmsData.realms : [];
    const userStatus = localStorage.getItem("status")

    const handleClose = () => {
        navigate('/user/docs')
    }

    const title = handleTitle(status, realmsData.document?.type)

    return !realms ?
        (<div className="preloader">
            <Icon loading name='spinner' size='massive'/>
        </div>) :
        (<Segment basic className="basicSegment">
                <div style={{minHeight: "30px"}}>
                    <ButtonClose handleClose={handleClose}/>
            {userStatus === "moderator" && <Button
                    color="blue"
                    style={{backgroundColor: "#3399ff"}}
                    onClick={() => {
                        navigate(`/addrealm/${docId}`)
                    }}>
                    Добавить {title}</Button>}
                </div>
                <Segment>
                    <b style={{fontSize: "18px"}}>Список {title} :</b>
                </Segment>
                {realms && realms.map((it, index) => {
                    return (
                        <Segment style={segmentStyle} key={index}>
                            <List.Item style={{maxWidth: "100%"}}>
                                <List.Content>
                                    <Menu stackable borderless
                                          style={{
                                              boxShadow: "none",
                                              border: "none",
                                          }}>
                                        <List.Header as='h2'>
                                            {it.name}
                                        </List.Header>
                                        <Menu.Menu
                                            position="right">
                                            <Button
                                                onClick={() => {
                                                    navigate(`/user/docs/realm/${docId}/${it.id}`)
                                                }}
                                                color="blue"
                                                floated='right'
                                                style={buttonStyle}>
                                                Открыть</Button>
                                            <Button
                                                onClick={() => {
                                                    dispatch(realmUp(it.id))
                                                        .then((response) => {
                                                            dispatch(fetchRealms(docId))
                                                        })
                                                }}
                                                style={greyButtonStyle}
                                                floated='right'>
                                                <Icon color="orange" name='arrow up'/>
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    dispatch(realmDown(it.id))
                                                        .then((response) => {
                                                            dispatch(fetchRealms(docId))
                                                        })
                                                }}
                                                floated='right'
                                                style={greyButtonStyle}>
                                                <Icon color="orange" name='arrow down'/>
                                                </Button>
                                        </Menu.Menu>
                                    </Menu>
                                </List.Content>
                            </List.Item>
                            <p style={{
                                fontSize: "20px",
                                fontWeight: "normal"
                            }}>{it.description}</p>
                            <div style={{
                                fontSize: "22px"
                            }}>Количество поправок: {it.amendmentCount}
                            </div>
                        </Segment>
                    )
                })}
            </Segment>
        )
}
export default RealmsList
