import React from 'react'
import {Header, Menu } from 'semantic-ui-react'
import {formatDate} from '../../../functions/date'


const DocsMenu = ({description, publishedDate}) => {
    const formattedDate = formatDate(publishedDate)
    return (
        <Menu stackable style={{maxWidth: "100%"}}>
            <Menu.Menu position="left">
                <Menu.Item>
                    <Header as='h5'>Описание:</Header>
                </Menu.Item>
                <Menu.Item style={{width: "48%"}}>
                    {description}
                </Menu.Item>
                <Menu.Item>
                    Дата:
                </Menu.Item>
                <Menu.Item style={{width: "500px"}}>
                    {formattedDate}
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    )
}
export default DocsMenu
