import React from 'react'
import {Button} from 'semantic-ui-react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'


const OneAmendmentActionButton = ({action, message, color, path, reload}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <Button
            onClick={() => {
                if (action !== null) {
                    action(true)
                };
                navigate(path);
                if (reload !== null) {
                dispatch(reload)
                }
            }}
            className="triggerButton"
            size='mini'
            style={{marginBottom: "2px",  backgroundColor: "#3399ff"}}
            color={color}>
            {message}
        </Button>
    )

}
export default OneAmendmentActionButton
