import React from 'react'
import {useMatch} from "react-router-dom";
import UserPage from '../components/User/UsersPage'
import MyRequests from '../components/User/MyRequests'
import Docs from '../components/User/Documents/Docs'
import MyAmendmentsList from '../components/User/Documents/Amendments/MyAmendmentsList'
import RealmsList from '../components/User/Documents/Realms/RealmsList'
import AmendmentsList from '../components/User/Documents/Amendments/AmendmentsList'
import AmendmentForm from '../components/User/Edits/Form'
import Manual from '../documents/Manual'

function DashboardRoutes() {
    const matchDocs = useMatch('/user/docs')
    const matchUser = useMatch('/user/profile')
    const matchManual = useMatch('/user/manual')
    const matchAmendment = useMatch('/user/myamendments')
    const matchRequests = useMatch('/user/myrequests')
    const matchRealms = useMatch('/user/docs/realms/:docId')
    const matchAmendmentsList = useMatch('/user/docs/realm/:docId/:id')
    const matchAmendmentForm = useMatch('/user/docs/realm/amendment/:docId/:id')
    //const matchDoc = useMatch('/user/docs/doc/:id')
    if (matchDocs) {
        return (<Docs/>)
    } else if (matchUser) {
        return (<UserPage/>)
    } else if (matchAmendment) {
        return (<MyAmendmentsList/>)
    } else if (matchRequests) {
        return (<MyRequests/>)
    } else if (matchRealms) {
        return <RealmsList/>
    } else if (matchAmendmentsList) {
        return <AmendmentsList/>
    } else if (matchAmendmentForm) {
        return <AmendmentForm/>
    } else if (matchManual) {
        return <Manual/>
    }
}


// <Route exact path="/user/docs/doc/:id" element={<ModalWindow/>}/>
// <Route exact path="/user/docs/pdf/:id" element={<ModalPDF/>}/>
// <Route exact path="/user/docs/realms/:docId" element={<RealmsList/>}/>
// <Route exact path="/user/docs/realm/:docId/:id" element={<AmendmentsList/>}/>
// <Route exact path="/user/docs/realm/amendment/:docId/:id" element={<Edits/>}/>

export default DashboardRoutes;
