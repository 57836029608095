import React from 'react';
import AmendmentMessageButton from '../Documents/Buttons/AmendmentMessageButton'
import {Modal} from 'semantic-ui-react'

const RealmEditorDelMessageModal = ({openDel, setOpenDel, docId}) => {
    return (
        <Modal
            open={openDel}
            basic size='mini'>
            <AmendmentMessageButton
                path={`/user/docs/realms/${docId}`} action={setOpenDel}
                message={"Удалено"} id={null} reload={null}/>
        </Modal>
    );
};

export default RealmEditorDelMessageModal;
