import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Table, Button, Icon, Segment, Image} from 'semantic-ui-react'
import {fetchLawyers} from '../../../redux/reducers/getLawyersSlise'
import {isElementInViewport} from '../../../functions/isElementInViewPort'
import {banLawyer, disallowLawyer} from '../../../redux/reducers/manageLawyersSlise'
import WarningModal from '../../Modals/WarningModal'
import MessageModal from '../../Modals/MessageModal'
import CoverLetterModal from '../../Modals/CoverLetterModal'
import {iconStyle, buttonWidth, widthAutoCell, diplomButtonStyle} from './styles'
import './Users.css'

const Approved = () => {
    const dispatch = useDispatch()
    const blockRef = useRef(null);
    useEffect(() => {
        dispatch(fetchLawyers())
    }, [dispatch])
    const [openBanMap, setOpenBanMap] = useState({})
    const [openBan, setOpenBan] = useState(false)
    const [approved, setApproved] = useState([])
    const [openCoverLetter, setOpenCoverLetter] = useState({})
    const [isHovered, setIsHovered] = useState(false);
    const lawyers = useSelector(state => state.getLawyersReducer.lawyers)
    const approvedLawyers = lawyers.filter(it => it.status === "active")

    useEffect(() => {
        function handleScroll() {
            if (isElementInViewport(blockRef.current)) {
                setIsHovered(true);
            } else {
                setIsHovered(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleOpenBanModal = (id) => {
        setOpenBanMap(prevState => ({
            ...prevState,
            [id]: true
        }));
    };

    const handleCloseBanModal = (id) => {
        setOpenBanMap(prevState => ({
            ...prevState,
            [id]: false
        }));
    };

    const handlerFunc = (id) => {
        dispatch(banLawyer(id))
            .then((response) => {
                if (response.status === 200 ||
                    response.status === 201) {
                    handleCloseBanModal(id)
                    setOpenBan(true)
                }
            })
            .catch((error) => {
                alert("Извините, произошла ошибка")
            })
    }

    const showPreloader = (itemId) => {
        if (approved.includes(itemId)) {
            setApproved(approved.filter(id => id !== itemId));
        } else {
            setApproved([...approved, itemId]);
        }
    }

    const handleOpenCoverLetter = (id) => {
        setOpenCoverLetter(prevState => ({
            ...prevState,
            [id]: true
        }));
    };

    const handleCloseCoverLetter = (id) => {
        setOpenCoverLetter(prevState => ({
            ...prevState,
            [id]: false
        }));
    };

    return <Segment basic className="segmentStyle">
        {!lawyers ?
            (<div className="preloader">
                <Icon loading name='spinner' size='massive'/>
                <div><h2>Loading...</h2></div>
            </div>) :
            (<Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><Icon name='check'
                                                size='large'
                                                style={iconStyle}/>
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Имя</Table.HeaderCell>
                        <Table.HeaderCell>E-mail</Table.HeaderCell>
                        <Table.HeaderCell>Сопроводительное письмо</Table.HeaderCell>
                        <Table.HeaderCell>Контакт</Table.HeaderCell>
                        <Table.HeaderCell>Соцсеть</Table.HeaderCell>
                        <Table.HeaderCell>Файл диплома</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {approvedLawyers.map((it, index) => {
                        return <> <Table.Row key={index}>
                            <Table.Cell collapsing>
                                {!approved.includes(it.id) ?
                                    <> <Button
                                        style={buttonWidth}
                                        inverted color='red'
                                        onClick={() => {
                                            showPreloader(it.id)
                                            dispatch(disallowLawyer(it.id))
                                                .then((responce) => {
                                                    dispatch(fetchLawyers())
                                                })
                                        }}>Отобрать доступ
                                    </Button>
                                        <div>
                                            <Button
                                                style={buttonWidth}
                                                inverted color='red'
                                                     onClick={() => {
                                                         handleOpenBanModal(it.id)
                                                     }}>Забанить</Button>
                                        </div>
                                    </> :
                                    (<Icon loading name='spinner' size='big'/>)
                                }
                            </Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.avatar ?
                                (<Image circular src={it.avatar} avatar size='mini'/>)
                                : (<Image circular src='https://cdn-icons-png.flaticon.com/512/6596/6596121.png' avatar
                                          size='mini'/>)}
                            </Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.name}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.email}</Table.Cell>
                            <Table.Cell  className={isHovered ? "hoveredCoverLetter" : "coverLetter"} ref={blockRef}
                                        onClick={() => {
                                handleOpenCoverLetter(it.id)
                            }}>
                                {it.coverLetter? `${it.coverLetter.slice(0,60)}...`: it.coverLetter}
                            </Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.contact}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>{it.socialLink}</Table.Cell>
                            <Table.Cell style={widthAutoCell}>
                                {it.professionalFile?
                                    <Button
                                        style={diplomButtonStyle}
                                        onClick={() => {
                                            window.open
                                            (`https://api.sanatsia.com/storage/diplomas/${it.professionalFile}`,
                                                '_blank')
                                        }}
                                    >Посмотреть диплом
                                    </Button>: null}
                            </Table.Cell>
                        </Table.Row>
                            <WarningModal open={openBanMap[it.id] || false}
                                          handler={() => handlerFunc(it.id)}
                                          click={() => handleCloseBanModal(it.id)}
                                          message={`Нажмите, чтобы забанить юриста ${it.name} и удалить все его поправки`}/>
                            <MessageModal open={openBan}
                                          path={`/dashboard/approved`}
                                          action={setOpenBan}
                                          message="Юрист забанен"
                                          id={null}
                                          reload={fetchLawyers}/>
                            <CoverLetterModal open={openCoverLetter[it.id] || false}
                                              action={() => handleCloseCoverLetter(it.id)}
                                              coverLetter={it.coverLetter}
                            />
                        </>
                    })}
                </Table.Body>
            </Table>)}
    </Segment>
}

export default Approved

