import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import "./AmendmentsEditor.css";
import {onMainTextChange, required, codexRequired, handleClear, modules} from './helpers'
import AmendmentsEditorForm from './AmendmentsEditorForm'
import {editTheAmendment} from '../../../redux/reducers/editAmendmentSlise'

const AmendmentEditor = () => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const quillRef = useRef(null);
    // const textareaRef = useRef(null);
    const amendment = useSelector(state => state.getOneAmendmentReducer.amendment)
    const [mainText, setMainText] = useState('')
    const [supplementaryText, setSupplementaryText] = useState('');
    const [financeText, setFinanceText] = useState('')
    const [revocationText, setRevocationText] = useState('')
    const [codex, setCodex] = useState('')

    const [open, setOpen] = useState(false);

    const [isValid, setIsValid] = useState(true);
    const [touched, setTouched] = useState(false);

    const [isCodexValid, setCodexIsValid] = useState(true);
    const [codexTouched, setCodexTouched] = useState(false);

    const onSupplementaryTextChange = (supplementaryText) => {
        setSupplementaryText(supplementaryText)
        localStorage.setItem(`${id}:supplementaryText`, supplementaryText);
    }

    const onFinanceTextChange = (financeText) => {
        setFinanceText(financeText)
        localStorage.setItem(`${id}:financeText`, financeText);
    }

    const onRevocationTextChange = (revocationText) => {
        setRevocationText(revocationText)
        localStorage.setItem(`${id}:revocationText`, revocationText);
    }

    const onCodexChange = (codex) => {
        setCodex(codex)
        localStorage.setItem(`${id}:codex`, codex);
    }

    const handleValidation = (value) => {
        if ((required && value.length === 0 && touched)
            || value === "<p></p>"
            || value === "<p><br></p>"
            || value === "<p> </p>") {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
        onMainTextChange(setMainText, value, id);
    };

    const handleCodexValidation = (event) => {
        const value = event.target.value;
        if ((codexRequired && value.length === 0 && codexTouched)) {
            setCodexIsValid(false);
        } else {
            setCodexIsValid(true);
        }
        onCodexChange(value);
    };

    const handleBlur = () => {
        if (!touched) {
            setTouched(true);
        }
        if (required && mainText?.trim() === "") {
            setIsValid(false);
        }
    };

    const handleCodexBlur = () => {
        if (!codexTouched) {
            setCodexTouched(true);
        }
        if (codexRequired && codex?.trim() === "") {
            setCodexIsValid(false);
        }
    };

    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        if (amendment) {
            if (amendment.mainText) {
                localStorage.setItem(`${id}:mainText`, amendment.mainText);
            }
            ;
            if (amendment.supplementaryText) {
                localStorage.setItem(`${id}:supplementaryText`, amendment.supplementaryText);
            }
            ;
            if (amendment.financeText) {
                localStorage.setItem(`${id}:financeText`, amendment.financeText);
            }
            if (amendment.revocationText) {
                localStorage.setItem(`${id}:revocationText`, amendment.revocationText);
            }
            if (amendment.codex) {
                localStorage.setItem(`${id}:codex`, amendment.codex);
            }
        }
    }, [amendment, amendment.codex, amendment.financeText, id, amendment.mainText, amendment.revocationText,
        amendment.supplementaryText]);

    useEffect(() => {
        setMainText(localStorage.getItem(`${id}:mainText`));
        setSupplementaryText(localStorage.getItem(`${id}:supplementaryText`));
        setFinanceText(localStorage.getItem(`${id}:financeText`));
        setRevocationText(localStorage.getItem(`${id}:revocationText`));
        setCodex(localStorage.getItem(`${id}:codex`));
    }, [amendment, id]);

    const handlePaste = (event) => {
        event.preventDefault();
        const text = event.clipboardData.getData('text/plain');
        const selection = quillRef.current.getEditor().getSelection();
        if (selection) {
            quillRef.current.getEditor().deleteText(selection.index, selection.length);
            quillRef.current.getEditor().insertText(selection.index, text);
        } else {
            quillRef.current.getEditor().insertText(0, text);
        }
    };

    const onSubmit = (event) => {
        dispatch(editTheAmendment(
            id,
            amendment.realm,
            mainText,
            supplementaryText,
            financeText,
            revocationText,
            {...amendment.lawyer},
            amendment.moderationComment,
            amendment.moderationStatus,
            amendment.updateDate,
            codex
        ))
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    handleClear(setMainText, setSupplementaryText, setFinanceText, setRevocationText, setCodex)
                }
                handleOpen()
            })
            .catch((error) => {
                alert("Извините, произошла ошибка редактирования")
            });
    }

    return (
        <AmendmentsEditorForm
            onSubmit={onSubmit} modules={modules} handleValidation={handleValidation}
            mainText={mainText} handleBlur={handleBlur} handlePaste={handlePaste}
            isValid={isValid} touched={touched} onSupplementaryTextChange={onSupplementaryTextChange}
            supplementaryText={supplementaryText} onFinanceTextChange={onFinanceTextChange}
            financeText={financeText} onRevocationTextChange={onRevocationTextChange}
            revocationText={revocationText} handleCodexValidation={handleCodexValidation}
            codex={codex} handleCodexBlur={handleCodexBlur} isCodexValid={isCodexValid}
            codexTouched={codexTouched} open={open} handleClose={handleClose} id={id}
            amendmentId={amendment.id} setCodex={setCodex} setMainText={setMainText}
            setSupplementaryText={setSupplementaryText} setFinanceText={setFinanceText}
            setRevocationText={setRevocationText}/>
    );
};

export default AmendmentEditor;
