import React from 'react';
import {NavLink} from 'react-router-dom';
import {Icon, Menu} from 'semantic-ui-react';

const AdminMenu = () => (
    <div>
        <Menu.Item as={NavLink} to={'/dashboard/requests'} exact>
            <Icon name='question circle outline'/>
            Запросы на подтверждение
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/dashboard/new'}>
            <Icon name='checkmark'/>
            Новые пользователи
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/dashboard/approved'}>
            <Icon name='checkmark'/>
            Одобренные
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/dashboard/disallow'}>
            <Icon name='minus circle'/>
            Отказано в доступе
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/dashboard/banned'}>
            <Icon name='ban'/>
            Забаненные
        </Menu.Item>
    </div>
)

export default AdminMenu

