import {createSlice} from '@reduxjs/toolkit';
import {realmAPI} from '../../api/api';

const initialState = {
    realm: [
        {
            id: '',
            docId: '',
            name: '',
            description: '',
            startingText: '',
            codex: '',
            document: {}
        }
    ]
};

export const postRealmSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        addRealm: (state, action) => {
            const newRealm = {
                id: action.payload.id,
                docId: action.payload.docId,
                name: action.payload.name,
                startingText: action.payload.startingText,
                codex: action.payload.codex,
                description: action.payload.description,
                document: action.payload.document
            };
            state.realm = [...state.realm, newRealm];
        }
    }
});

export const {addRealm} = postRealmSlice.actions;

export const addNewRealm = (docId, name, description, startingText, codex, document) => (dispatch) => {
    return new Promise((resolve, reject) => {
        realmAPI.add(docId, name, description, startingText, codex, document)
            .then((response) => {
                dispatch(addRealm({docId, name, description, startingText, codex, document}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export default postRealmSlice.reducer;
