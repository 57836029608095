import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {requestForTheRealm} from '../../redux/reducers/requestForRealmSlice'
import {Modal, Button, Form} from 'semantic-ui-react'

const PromptModal = ({open, action, id, setOpenMessage}) => {
    const dispatch = useDispatch()
    const [comment, setComment] = useState('')

    const handleCommentChange = (event) => {
        const value = event.target.value;
        setComment(value);
    };
    const handlerFunc = () => {
        dispatch(requestForTheRealm(id, comment))
            .then((response) => {
                if (response.status === 200 ||
                    response.status === 201) {
                    action(false)
                    setOpenMessage(true)
                }
            })
            .catch((error) => {
                alert("Извините, произошла ошибка")
            })
    }
    return <Modal
        as={Form}
        open={open}
        basic size='mini'>
        <Modal.Header>
            Напишите здесь сообщение модератору, чтобы он предоставил вам доступ к этой области
        </Modal.Header>
        <Form.TextArea
            name='message'
            value={comment}
            placeholder='Ваше сообщение модератору'
            onChange={handleCommentChange}
        />
        <Button onClick={() => {
            handlerFunc()
        }}>Отправить</Button>
        <Button style={{marginLeft: "20px"}} onClick={() => {
            action(false)
        }}>Отмена</Button>
    </Modal>
}

export default PromptModal

