import React from 'react'
import {Menu, Icon} from 'semantic-ui-react'
import {NavLink, useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {removeUser} from '../../redux/reducers/userSlise'
import './Header.css'

const UsersHeader = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onHandleOut = () => {
        dispatch(removeUser())
            .then(() => {
                navigate('/')
            })
    }
    return <Menu stackable inverted color='blue'
                 style={{marginBottom: "0", backgroundColor: "#3399ff"}}>
        <NavLink to="/" style={{lineHeight: "11px"}}>
            <div className="logo">
                <img src="https://api.sanatsia.com/logo.svg"
                     alt="logo"
                     style={{width: "200px",
                         height: "68px"}}/>
            </div>
        </NavLink>
        <Menu.Menu position="right" style={{padding: "1%"}}>
            <Menu.Item as={NavLink} to={'/user/profile'}>
                <Icon name="user"/>
                Моя страница
            </Menu.Item>
            <Menu.Item onClick={onHandleOut}>
                <Icon name="sign-out"/>
                Выйти из аккаунта
            </Menu.Item>
        </Menu.Menu>
    </Menu>
}

export default UsersHeader
