import {configureStore} from '@reduxjs/toolkit'
import thunkMiddleware from "redux-thunk"
import docsReducer from './getDocsSlise'
import postReducer from './postAmendmentSlise'
import realmsReducer from './getRealmsSlice'
import realmReducer from './getRealmSlise'
import amendmentsReducer from './getAmendmentsSlice'
import myAmendmentsReducer from './getMyAmendmentsSlise'
import editAmendmentReducer from './editAmendmentSlise'
import getOneAmendmentReducer from './getOneAmendmentSlice'
import deleteAmendmentReducer from './deleteAmendmentSlise'
import postRealmReducer from './postRealmSlise'
import amendmentsButtonReducer from './amendmentSlise'
import reworkAmendmentsReducer from './reworkAmendmentSlise'
import getLawyersReducer from './getLawyersSlise'
import commentReducer from './commentAmendmentSlise'
import getCommentsReducer from './getCommentsSlise'
import uploadReducer from './fileUploadSlise'
import lawyerRealmSlice from './lawyerRealmSlise'
import getRealmRequestsSlice from './getRealmRequestsSlise'
import getNewRealmRequestsSlice from './getNewRealmRequestsSlise'
import preparedAmendmentsReducer from './getPreparedAmendmentsSlise'
import userSlice from './userSlise'
import accessSlice from './accessSlice'


const store = configureStore({
    reducer: {
        userSlice,
        docsReducer,
        postReducer,
        realmsReducer,
        realmReducer,
        amendmentsReducer,
        myAmendmentsReducer,
        editAmendmentReducer,
        getOneAmendmentReducer,
        deleteAmendmentReducer,
        postRealmReducer,
        amendmentsButtonReducer,
        reworkAmendmentsReducer,
        getLawyersReducer,
        commentReducer,
        getCommentsReducer,
        uploadReducer,
        lawyerRealmSlice,
        getRealmRequestsSlice,
        getNewRealmRequestsSlice,
        preparedAmendmentsReducer,
        accessSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(thunkMiddleware)
})

export default store

