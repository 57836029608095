export const style = {
    marginTop: "1rem",
    marginBottom: "1rem"
}

export const cardStyle = {marginTop: "1rem"}

export const iconStyle = {marginLeft: "3px"}

export const aStyle = {float: "right"}

export const moderMessageStyle = {marginTop: "1rem", fontSize: "16px"}

export const moderCommentStyle = {color: "black", marginLeft: "1rem"}

export const amendmentStyle = {
    fontSize: "20px",
    color: "black",
    overflowX: "auto"
}
export const draftAmendmentStyle = {
    fontSize: "20px",
    color: "black",
    backgroundColor: "white",
    overflowX: "auto"
}

export const buttonStyle = {
    marginBottom: "2px",
    backgroundColor: "#3399ff"
}

export const buttonRequestStyle = {
    marginBottom: "2px",
    backgroundColor: "lightgreen"
}

export const textHeaderStyle = {marginTop: "1rem"}

