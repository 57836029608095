import React, {useRef, useEffect} from 'react';
import "react-quill/dist/quill.snow.css";
import "./AmendmentsEditor.css";
import AmendmentsEditorFormJSX from './AmendmentsEditorFormJSX'

const AmendmentsEditorForm = ({
                                  onSubmit, modules, handleValidation, mainText, handleBlur, handlePaste,
                                  isValid, touched, onSupplementaryTextChange, supplementaryText,
                                  onFinanceTextChange, financeText, onRevocationTextChange, revocationText,
                                  handleCodexValidation, codex, handleCodexBlur, isCodexValid, codexTouched,
                                  open, handleClose, id, amendmentId, setCodex, setMainText, setSupplementaryText,
                                  setFinanceText, setRevocationText
                              }) => {
    const textareaRef = useRef(null);
    const mainTextRef = useRef(null);
    const supplTextRef = useRef(null)
    const financeTextRef = useRef(null)
    const revocationTextRef = useRef(null)

    const addEvent = (func) => {
        document.addEventListener('mousedown', func);
    }
    const removeEvent = (func) => {
        document.removeEventListener('mousedown', func);
    }
    const regExp = /<p><br><\/p>(\s*<p><br><\/p>)+/g

    const oneNewLine = '<p><br></p>'

    useEffect(() => {
        const handleOutsideClickMain = (e) => {
            if (mainTextRef.current && mainTextRef.current.editor &&
                !mainTextRef.current.editor.root.contains(e.target)) {
                setMainText(mainText.replace(regExp, oneNewLine));
            }
        };
        addEvent(handleOutsideClickMain)
        return () => {
            removeEvent(handleOutsideClickMain)
        };
        // eslint-disable-next-line
    }, [mainText, setMainText]);

    useEffect(() => {
        const handleOutsideClickSuppl = (e) => {
            if (supplTextRef.current && supplTextRef.current.editor &&
                !supplTextRef.current.editor.root.contains(e.target)) {
                setSupplementaryText(supplementaryText.replace(regExp, oneNewLine));
            }
        };
        addEvent(handleOutsideClickSuppl)
        return () => {
            removeEvent(handleOutsideClickSuppl)
        };
        // eslint-disable-next-line
    }, [setSupplementaryText, supplementaryText]);

    useEffect(() => {
        const handleOutsideClickFinance = (e) => {
            if (financeTextRef.current && financeTextRef.current.editor &&
                !financeTextRef.current.editor.root.contains(e.target)) {
                setFinanceText(financeText.replace(regExp, oneNewLine));
            }
        };
        addEvent(handleOutsideClickFinance)
        return () => {
            removeEvent(handleOutsideClickFinance)
        };
        // eslint-disable-next-line
    }, [setFinanceText, financeText]);

    useEffect(() => {
        const handleOutsideClickRevocation = (e) => {
            if (revocationTextRef.current && revocationTextRef.current.editor &&
                !revocationTextRef.current.editor.root.contains(e.target)) {
                setRevocationText(revocationText.replace(regExp, oneNewLine));
            }
        };
        addEvent(handleOutsideClickRevocation)
        return () => {
            removeEvent(handleOutsideClickRevocation)
        };
        // eslint-disable-next-line
    }, [setRevocationText, revocationText]);


    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (textareaRef.current && !textareaRef.current.contains(e.target)) {
                setCodex(codex.replace(/(\n{3,})/g, "\n\n"));
            }
        };
        addEvent(handleOutsideClick)
        return () => {
            removeEvent(handleOutsideClick)
        };
    }, [codex, setCodex]);

    return <AmendmentsEditorFormJSX onSubmit={onSubmit} modules={modules} handleValidation={handleValidation}
                                    mainText={mainText} handleBlur={handleBlur} handlePaste={handlePaste}
                                    isValid={isValid} touched={touched}
                                    onSupplementaryTextChange={onSupplementaryTextChange}
                                    supplementaryText={supplementaryText} onFinanceTextChange={onFinanceTextChange}
                                    financeText={financeText} onRevocationTextChange={onRevocationTextChange}
                                    revocationText={revocationText} handleCodexValidation={handleCodexValidation}
                                    codex={codex} handleCodexBlur={handleCodexBlur} isCodexValid={isCodexValid}
                                    codexTouched={codexTouched} open={open} handleClose={handleClose} id={id}
                                    amendmentId={amendmentId} setCodex={setCodex} setMainText={setMainText}
                                    setSupplementaryText={setSupplementaryText} setFinanceText={setFinanceText}
                                    setRevocationText={setRevocationText}textareaRef={textareaRef}
                                    mainTextRef={mainTextRef} supplTextRef={supplTextRef}
                                    financeTextRef={financeTextRef} revocationTextRef={revocationTextRef}/>
};

export default AmendmentsEditorForm;
