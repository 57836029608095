export const buttonStyle = {
    minWidth: "100px",
    minHeight: "40px",
    maxHeight: "40px",
    padding: "10px",
    backgroundColor: "#3399ff"
}
export const segmentStyle = {
    maxWidth: "100%",
    boxShadow: "0 1px 2px 0 #3399ff",
    border: "1px solid #3399ff",
    overflowX: "auto",
}

export const greyButtonStyle = {
    minWidth: "100px",
    minHeight: "40px",
    maxHeight: "40px",
    padding: "10px",
    backgroundColor: "gainsboro"
}
