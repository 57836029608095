import React, {useEffect} from 'react'
import {Menu, Icon, Label} from 'semantic-ui-react'
import {NavLink, useNavigate} from 'react-router-dom'
import {useDispatch,  useSelector} from 'react-redux'
import {removeUser} from '../../redux/reducers/userSlise'
import {fetchLawyers} from '../../redux/reducers/getLawyersSlise'
import {getNewRequests} from '../../redux/reducers/getNewRealmRequestsSlise'
import {fetchPreparedAmendments} from '../../redux/reducers/getPreparedAmendmentsSlise'
import './Header.css'

const ModeratorsHeader = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const status = "new"
    useEffect(() => {
        dispatch(fetchLawyers())
        dispatch(getNewRequests(status))
        dispatch(fetchPreparedAmendments())
    }, [dispatch])

    const lawyers = useSelector(state => state.getLawyersReducer.lawyers)
    const newLawyers = lawyers.filter(it => it.status === "new")
    const newLawyersWithCoverLetter = newLawyers.filter(it => it.coverLetter)
    const newLawyersLength = newLawyersWithCoverLetter?.length

    const newRequests = useSelector(state => state.getNewRealmRequestsSlice.requests)
    const newRequestsLength = newRequests?.length

    const preparedAmendments = useSelector(state => state.preparedAmendmentsReducer.preparedAmendments)
    const preparedAmendmentsLength = preparedAmendments.length

    const onHandleOut = () => {
        dispatch(removeUser())
            .then(() => {
                navigate('/')
            })
    }
    return <Menu stackable inverted color='blue'
                 style={{marginBottom: "0", backgroundColor: "#3399ff"}}>
        <NavLink to="/" style={{lineHeight: "11px"}}>
            <div className="logo">
                <img src="https://api.sanatsia.com/logo.svg"
                     alt="logo"
                     style={{width: "200px",
                         height: "68px"}}/>
            </div>
        </NavLink>
        <Menu.Menu position="right" style={{padding: "1%"}}>
            <Menu.Item as={NavLink} to={'/user/profile'}>
                <Icon name="user"/>
                Моя страница
            </Menu.Item>
            <Menu.Item as={NavLink} to={'/dashboard/requests'} style={{marginLeft: "1%"}}>
                <Icon name="users"/>
                Пользователи
                {newLawyersLength > 0 ?
                <Label color='red' floating>
                    {newLawyersLength}
                </Label> : null}
            </Menu.Item>
            <Menu.Item as={NavLink} to={'/editdashboard/requests'} style={{marginLeft: "1%"}}>
                <Icon name="edit outline"/>
                Правки
                {newRequestsLength > 0 ?
                    <Label color='red' floating>
                    {newRequestsLength}
                </Label> : null}
                {preparedAmendmentsLength > 0 ?
                    <Label color='green' floating className="greenLabel">
                        {preparedAmendmentsLength}
                    </Label> : null}
            </Menu.Item>
            <Menu.Item onClick={onHandleOut}>
                <Icon name="sign-out"/>
                Выйти из аккаунта
            </Menu.Item>
        </Menu.Menu>
    </Menu>
}

export default ModeratorsHeader
