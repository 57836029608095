export const statusFunc = (amendmentStatus) => {
    let status = ""
    if (amendmentStatus === "draft") {
        status = "Черновик"
    } else if (amendmentStatus === "proposed") {
        status = "Поправка предложена к публикации"
    } else if (amendmentStatus === "revoked") {
        status = "Поправка отправлена на доработку (см. комментарий модератора)"
    } else if (amendmentStatus === "denied") {
        status = "Поправка отклонена (см. комментарий модератора)"
    } else if (amendmentStatus === "published") {
        status = "Поправка опубликована"
    }
    return status
}

export const colorFunc = (amendmentStatus) => {
    let color = ""
    if (amendmentStatus === "draft") {
        color = "olive"
    } else if (amendmentStatus === "proposed") {
        color = "olive"
    } else if (amendmentStatus === "revoked") {
        color = "orange"
    } else if (amendmentStatus === "denied") {
        color = "red"
    } else if (amendmentStatus === "published") {
        color = "green"
    }
    return color
}
