import React from 'react'
import {Segment} from 'semantic-ui-react'

const Policy = () => {
    return (
            <Segment>
                <h3>Privacy Policy</h3>

                Last updated: 22/06/2023<br/>

                Санация Права ("мы", "нас" или "наш") заботится о вашей конфиденциальности и стремится защитить ваши персональные данные.<br/>
                Настоящая Политика конфиденциальности объясняет, как мы собираем,
                используем, раскрываем и защищаем ваши персональные данные в связи с вашим использованием нашего веб-сайта https://form.sanatsia.com/ (далее "Сайт").<br/>

                <div><h3>1. Сбор персональной информации</h3>
                    Мы можем собирать различные типы персональной информации, включая, но не ограничиваясь:

                    Имя, фамилия и электронная почта.
                    Данные о вашем взаимодействии с Сайтом (например, информация о просмотренных страницах, действиях, совершенных на Сайте)
                    Другую информацию, которую вы предоставляете нам по своему усмотрению</div>



                <h3>2. Использование персональной информации</h3>
                Мы используем собранную персональную информацию для следующих целей:

                Предоставление и поддержка наших услуг и функций Сайта
                Обработка ваших запросов, комментариев или обратной связи
                Улучшение и оптимизация Сайта и его содержимого
                Анализ и изучение трендов использования, статистики и поведения пользователей

                <h3>3. Раскрытие персональной информации</h3>
                Мы можем раскрывать вашу персональную информацию третьим сторонам в следующих случаях:

                Когда это необходимо для предоставления вам услуг или выполнения запросов (например, передача информации платежным системам для обработки платежей)
                При сотрудничестве с поставщиками услуг, которые помогают нам предоставлять и поддерживать Сайт (например, хостинг-провайдеры, аналитические платформы)
                При соблюдении законодательных требований, судебных решений или в случаях, когда мы считаем такие действия необходимыми для соблюдения правовых норм или защиты наших прав или безопасности

                <h3>4. Защита персональной информации</h3>
                Мы принимаем меры для обеспечения безопасности вашей персональной информации и предотвращения несанкционированного доступа, использования или раскрытия. Мы применяем разумные технические и организационные меры, такие как шифрование данных, контроль доступа и мониторинг, чтобы защитить вашу персональную информацию от потери, кражи или несанкционированного использования.

                <h3>5. Сроки хранения персональной информации</h3>
                Мы храним вашу персональную информацию только в течение необходимого периода времени для достижения целей, описанных в настоящей Политике конфиденциальности, или в соответствии с применимыми законодательными требованиями.

                <h3>6. Ваши права</h3>
                У вас есть определенные права в отношении вашей персональной информации. Вы можете запросить доступ, исправление, удаление или ограничение обработки вашей персональной информации, а также воспротивиться ее обработке или запретить автоматизированное принятие решений. Вы также можете обратиться к нам с вопросами, комментариями или жалобами относительно вашей персональной информации.

                <h3>7. Ссылки на другие сайты</h3>
                Наш Сайт может содержать ссылки на другие веб-сайты, которые не контролируются нами. Мы не несем ответственности за контент, политику конфиденциальности или практики таких веб-сайтов третьих сторон.

                <h3> 8. Изменения в Политике конфиденциальности</h3>
                Мы можем периодически обновлять настоящую Политику конфиденциальности. При внесении изменений мы уведомим вас путем размещения обновленной версии на Сайте. Рекомендуем вам периодически проверять Политику конфиденциальности для ознакомления с ее последней версией.

                <h3>9. Связь с нами</h3>
                Если у вас есть вопросы, комментарии или запросы относительно нашей Политики конфиденциальности или обработки вашей персональной информации, пожалуйста, свяжитесь с нами по следующим контактным данным: info@sanatsia.com

                <h3>10. Принятие Политики конфиденциальности</h3>
                При использовании нашего Сайта, вы подтверждаете, что прочитали, поняли и согласны с условиями настоящей Политики конфиденциальности.
            </Segment>
    )
}
export default Policy
