import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {instance} from '../../api/api';


export const deleteRealm = createAsyncThunk(
    'delete/deleteRealm',
    async (realm_id) => {
        const response = await
            instance.delete(`/realm/${realm_id}`);
        return response
    }
);

const initialState = {
    realm: {},
    status: 'idle',
    error: ''
}

const deleteRealmSlice = createSlice({
    name: 'delete',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(deleteRealm.pending, (state) => {
            state.status = 'loading'
        })
            .addCase(deleteRealm.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.realm = {};
                state.error = ""
            })
            .addCase(deleteRealm.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

    }
});

export default deleteRealmSlice.reducer;
