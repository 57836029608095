import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import CommentBox from './Comments/CommentBox'
import CardLinks from './CardHrefs'
import parse from 'html-react-parser';
import {fetchComments} from '../../../../redux/reducers/getCommentsSlise'
import {useComments} from '../../../../hooks/commentsSelector'
import {cardStyle, iconStyle, aStyle, moderCommentStyle, moderMessageStyle} from './styles'
import {Button, Icon, Message} from 'semantic-ui-react'

const AmendmentCard = ({
                           id,
                           lawyerId,
                           mainText,
                           supplementaryText,
                           financeText,
                           revocationText,
                           name,
                           status,
                           moderationComment,
                           moderStatus,
                           updateDate,
                           codex
                       }) => {
    const dispatch = useDispatch()
    const {
        comments,
        countOfMainComments,
        countOfFinanceComments,
        countOfRevocationComments,
        countOfSupplementaryComments
    } = useComments()
    const [showMainComments, setShowMainComments] = useState(false);
    const handleShowMainComments = () => {
        setShowMainComments(!showMainComments);
    };
    const [showRevocationComments, setShowRevocationComments] = useState(false);
    const handleShowRevocationComments = () => {
        setShowRevocationComments(!showRevocationComments);
    };
    const [showFinanceComments, setShowFinanceComments] = useState(false);
    const handleShowFinanceComments = () => {
        setShowFinanceComments(!showFinanceComments);
    };

    const [showSupplementaryComments, setShowSupplementaryComments] = useState(false);
    const handleShowSupplementaryComments = () => {
        setShowSupplementaryComments(!showSupplementaryComments);
    };

    useEffect(() => {
        if (id) {
            dispatch(fetchComments(id))
        }
    }, [dispatch, id])

    const parsedMainText = parse(mainText || "")
    const parsedSupplementaryText = parse(supplementaryText || "")
    const parsedFinanceText = parse(financeText || "")
    const parsedRevocationText = parse(revocationText || "")

    const upLink = <a style={aStyle} href="#up">Наверх ⇧</a>

    return (<>
            {moderationComment && (moderStatus === "denied" || moderStatus === "revoked") ?
                <Message style={moderMessageStyle} color="orange">
                    <b>Комментарий модератора: </b> <span
                    style={moderCommentStyle}>{moderationComment}</span>
                </Message> : null}
            <CardLinks/>
            <div>
                <div id="main"><b>Текст поправки:</b></div>
                <div key={`${id}`}>{parsedMainText}</div>
            </div>
            <Button style={cardStyle} onClick={handleShowMainComments}>
                {showMainComments ? 'Скрыть комментарии' :
                    `Комментариев: ${countOfMainComments}`}
                {showMainComments ?
                    <Icon style={iconStyle} name='angle up'/> :
                    <Icon style={iconStyle} name='angle down'/>}
            </Button>
            {showMainComments &&
            <CommentBox id={id}
                        lawyerId={lawyerId}
                        commented='main'
                        comments={comments}/>}
            <div style={cardStyle}>
                <div id="supplementary">
                    <b>Пояснительная записка:</b>
                    {upLink}
                </div>
                <div key={`${id}1`}>{parsedSupplementaryText}</div>
            </div>
            <Button style={cardStyle} onClick={handleShowSupplementaryComments}>
                {showSupplementaryComments ? 'Скрыть комментарии' :
                    `Комментариев: ${countOfSupplementaryComments}`}
                {showSupplementaryComments ?
                    <Icon style={iconStyle} name='angle up'/> :
                    <Icon style={iconStyle} name='angle down'/>}
            </Button>
            {showSupplementaryComments &&
            <CommentBox id={id}
                        lawyerId={lawyerId}
                        commented='supplementary'
                        comments={comments}/>}
            <div style={cardStyle} id="finance">
                <b>Финансовое обоснование:</b>
                {upLink}
            </div>
            <div key={`${id}2`}>{parsedFinanceText}</div>
            <Button style={cardStyle} onClick={handleShowFinanceComments}>
                {showFinanceComments ? 'Скрыть комментарии' :
                    `Комментариев: ${countOfFinanceComments}`}
                {showFinanceComments ?
                    <Icon style={iconStyle} name='angle up'/> :
                    <Icon style={iconStyle} name='angle down'/>}
            </Button>
            {showFinanceComments &&
            <CommentBox id={id}
                        lawyerId={lawyerId}
                        commented='finance'
                        comments={comments}/>}
            <div style={cardStyle}>
                <div id="revocation">
                    <b>Какие законы или другие НПА потребуется
                        изменить или отменить:</b>
                    {upLink}
                </div>
                <div key={`${id}3`}>{parsedRevocationText}</div>
                <Button style={cardStyle} onClick={handleShowRevocationComments}>
                    {showRevocationComments ? 'Скрыть комментарии' :
                        `Комментариев: ${countOfRevocationComments}`}
                    {showRevocationComments ?
                        <Icon style={iconStyle} name='angle up'/> :
                        <Icon style={iconStyle} name='angle down'/>}
                </Button>
                {showRevocationComments &&
                <CommentBox id={id}
                            lawyerId={lawyerId}
                            commented='revocation'
                            comments={comments}/>}
                <div id="codex" style={cardStyle}>
                    <div>
                        <b>Ссылки на Собрание Законодательства РФ</b>
                        {upLink}
                    </div>
                    {codex}
                </div>
            </div>
        </>
    )
}
export default AmendmentCard
