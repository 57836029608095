import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Segment, Icon} from 'semantic-ui-react'
import {fetchOneAmendment} from '../../../../redux/reducers/getOneAmendmentSlice'
import {deleteAmendment} from '../../../../redux/reducers/deleteAmendmentSlise'
import {proposeNewAmendment} from '../../../../redux/reducers/amendmentSlise'
import {acceptNewAmendment} from '../../../../redux/reducers/amendmentSlise'
import {revokeNewAmendment} from '../../../../redux/reducers/reworkAmendmentSlise'
import {denyNewAmendment} from '../../../../redux/reducers/reworkAmendmentSlise'
import AmendmentsMessage from './AmendmentMessage'
import {statusFunc, colorFunc} from '../../../../functions/status'
import {formatDate} from '../../../../functions/date'
import {amendmentStyle, draftAmendmentStyle} from './styles'
import './MyAmendmentsList.css'

const OneAmendment = () => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const [open, setOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [openProp, setOpenProp] = useState(false);
    const [proposeOK, setProposeOK] = useState(false)
    const [openAccept, setOpenAccept] = useState(false)
    const [acceptOK, setAcceptOK] = useState(false)
    const userStatus = localStorage.getItem('status')
    useEffect(() => {
        dispatch(fetchOneAmendment(id))
    }, [dispatch, id])
    const amendment = useSelector(state => state.getOneAmendmentReducer.amendment)
    const lawyerId = amendment?.lawyer_id
    const loadingStatus = useSelector(state => state.getOneAmendmentReducer.status)
    const userId = useSelector(state => state.userSlice.profile?.id)

    const handleDelete = () => {
        dispatch(deleteAmendment(id));
        setOpen(false);
        setOpenDel(true)
    }

    const handlePropose = () => {
        dispatch(proposeNewAmendment(id))
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setOpenProp(false)
                    setProposeOK(true)
                }
            }).catch((error) => {
            alert("Извините, произошла ошибка")
        });
    }

    const handleAccept = () => {
        dispatch(acceptNewAmendment(id))
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setOpenAccept(false)
                    setAcceptOK(true)
                }
            }).catch((error) => {
            alert("Извините, произошла ошибка")
        });
    }

    const handleDeny = () => {
        const comment = prompt('Напишите причину отказа в принятии поправки:');
        if (comment) {
            dispatch(denyNewAmendment(id, comment))
                .then(() => {
                    dispatch(fetchOneAmendment(id))
                });
        }
    };

    const handleRevoke = () => {
        const comment = prompt('Напишите причину отправления поправки на доработку:');
        if (comment) {
            dispatch(revokeNewAmendment(id, comment))
                .then(() => {
                    dispatch(fetchOneAmendment(id))
                });
        }
    };
    const modStatus = amendment.moderationStatus
    const status = statusFunc(modStatus)
    const color = colorFunc(modStatus)
    const formattedDate = formatDate(amendment.updateDate)

    return (
        <Segment basic className="basicSegment">
            {loadingStatus === "succeeded" ?
                <AmendmentsMessage
                    loadingStatus={loadingStatus} color={color} modStatus={modStatus}
                    draftAmendmentStyle={draftAmendmentStyle} amendmentStyle={amendmentStyle}
                    formattedDate={formattedDate} amendment={amendment} status={status}
                    userId={userId} lawyerId={lawyerId} id={id} userStatus={userStatus} open={open}
                    setOpen={setOpen} openDel={openDel} setOpenDel={setOpenDel} openProp={openProp}
                    setOpenProp={setOpenProp} proposeOK={proposeOK} setProposeOK={setProposeOK}
                    openAccept={openAccept} setOpenAccept={setOpenAccept} acceptOK={acceptOK} setAcceptOK={setAcceptOK}
                    handleRevoke={handleRevoke} handleDeny={handleDeny} handleAccept={handleAccept}
                    handleDelete={handleDelete} handlePropose={handlePropose}/> :
                (<div className="preloader"><Icon loading name='spinner' size='massive'/>
                    <div><h2>Loading...</h2></div>
                </div>)}
        </Segment>
    )
}
export default OneAmendment
