export const formatDate = (updateDate) => {
    const dateStr = updateDate;
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate
}

export function sortByDate(a, b) {
    const dateA = new Date(a.updateDate);
    const dateB = new Date(b.updateDate);
    if (dateA > dateB) {
        return -1;
    } else if (dateA < dateB) {
        return 1;
    } else {
        return 0;
    }
}

export function sortByCreateDate(a, b) {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);
    if (dateA > dateB) {
        return -1;
    } else if (dateA < dateB) {
        return 1;
    } else {
        return 0;
    }
}
