import React from 'react'
import {Container, Accordion} from 'semantic-ui-react';


const Donate = () => {

    const panels = [
        {
            key: 'USDT (BEP20)',
            title: 'USDT (BEP20)',
            content: [
                '0xd6f709df9d2c29980aeca379bdfed0ca150a4ed7',
            ].join(' '),
        },
        {
            key: 'USDT(TRC20)',
            title: 'USDT(TRC20)',
            content: [
                'TYaiuGmu6dKP4SmF4zzhXHPD4KT25WvKrG',
            ].join(' '),
        },
        {
            key: 'BTC (BITCOIN)',
            title: 'BTC (BITCOIN)',
            content: [
                '129FeycQvHqDwW75PZUvHS8ZseJ8cikMSN',
            ].join(' '),
        },
        {
            key: 'BTC (BEP20)',
            title: 'BTC (BEP20)',
            content: [
                '0xd6f709df9d2c29980aeca379bdfed0ca150a4ed7',
            ].join(' '),
        },
        {
            key: 'BTC (ERC20)',
            title: 'BTC (ERC20)',
            content: [
                '0xd6f709df9d2c29980aeca379bdfed0ca150a4ed7',
            ].join(' '),
        },
        {
            key: 'ETH (BEP20)',
            title: 'ETH (BEP20)',
            content: [
                '0xd6f709df9d2c29980aeca379bdfed0ca150a4ed7',
            ].join(' '),
        },
        {
            key: 'ETH (ERC20)',
            title: 'ETH (ERC20)',
            content: [
                '0xd6f709df9d2c29980aeca379bdfed0ca150a4ed7',
            ].join(' '),
        },
        {
            key: 'BTC (BEP2)',
            title: 'BTC (BEP2)',
            content: [
                'bnb165q9dz39mqh789zuuuqwkv22plut6f4nzy9jc9',
            ].join(' '),
        }
    ]

    return (<>
            <Container style={{marginTop: "2rem", fontSize: "15px"}}>
                <h3>О финансировании</h3>

                <p>В настоящий момент проект финансируется собственными средствами и небольшими пожертвованиями
                    нескольких
                    частных лиц.</p>

                <p>Для довинчивания сайта, оплаты хостинга, корректировки текстов и многого другого, нам, конечно, денег
                    не
                    хватает. Если есть желание - можете задонатить нам криптой. Сейчас это единственный способ
                    безопасных
                    переводов. Мы не собираем донаты на российские карты. Сейчас это небезопасно для всех участников
                    транзакции.</p>
                <Accordion defaultActiveIndex={0} panels={panels}/>
            </Container>
        </>
    )
}
export default Donate
