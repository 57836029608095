import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getUser} from './redux/reducers/userSlise'
import {useNavigate} from 'react-router-dom'

const GetStatus = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state.userSlice.profile)
    useEffect(() => {
        if (!user) {
            dispatch(getUser())
                .then(() => {
                    const status = localStorage.getItem("status")
                        if (status === "moderator" || status === "active") {
                            navigate('/user/docs')
                    } else {
                        navigate('/user/profile')
                    }
                })
                .catch(error => {
                    alert("Ошибка")
                });
        } else {
            localStorage.setItem("status", user?.status)
        }
        // eslint-disable-next-line
    }, [dispatch, navigate])
    return (
        <h1>Loading...</h1>
    )
}

export default GetStatus
