import {createSlice} from '@reduxjs/toolkit';
import {amendmentsAPI} from '../../api/api';

const initialState = {
        amendmentsData: [
            {
                id: '',
                realm: '',
                mainText: '',
                supplementaryText: '',
                financeText: '',
                revocationText: '',
                lawyer: '',
                moderationComment: '',
                moderationStatus: '',
                updateDate: '',
                codex: ''
            }
        ]
    }
;

export const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        addAmendment: (state, action) => {
            const newAmendment = {
                id: action.payload.id,
                realm: action.payload.realm,
                mainText: action.payload.mainText,
                supplementaryText: action.payload.supplementaryText,
                financeText: action.payload.financeText,
                revocationText: action.payload.revocationText,
                lawyer: action.payload.lawyer,
                moderationComment: action.payload.moderationComment,
                moderationStatus: action.payload.moderationStatus,
                updateDate: action.payload.updateDate,
                codex: action.payload.codex
            };
            state.amendmentsData = [...state.amendmentsData, newAmendment];
        }
    }
});

export const {addAmendment} = postSlice.actions;

export const addNewAmendment = (id, realm, mainText, supplementaryText,
                                financeText, revocationText, lawyer,
                                moderationComment, moderationStatus, updateDate, codex) => (dispatch) => {
    return new Promise((resolve, reject) => {
        amendmentsAPI.add(id, realm, mainText, supplementaryText,
            financeText, revocationText, lawyer,
            moderationComment, moderationStatus, updateDate, codex)
            .then((response) => {
                dispatch(addAmendment({
                    id, realm, mainText, supplementaryText,
                    financeText, revocationText, lawyer,
                    moderationComment, moderationStatus, updateDate, codex
                }));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export default postSlice.reducer;
