import {createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api';


const initialState = {
        id: ""
    };

export const amendmentSlice = createSlice({
    name: 'amendment',
    initialState,
    reducers: {
        proposeAmendment: (state, action) => {
            return {
                ...state,
                id: action.payload.id
            };
        },
        acceptAmendment: (state, action) => {
            return {
                ...state,
                id: action.payload.id
            }
        }
    }
});

export const {proposeAmendment, acceptAmendment} = amendmentSlice.actions;

export const proposeNewAmendment = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/amendment/propose/${id}`)
            .then((response) => {
                dispatch(proposeAmendment({id}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export const acceptNewAmendment = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/amendment/accept/${id}`)
            .then((response) => {
                dispatch(acceptAmendment({id}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};

export default amendmentSlice.reducer;
