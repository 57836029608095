import React from 'react'
import RealmEditor from './RealmEditor'
import {useSelector} from 'react-redux'
import {editTheRealm} from '../../../redux/reducers/editRealmSlise'


const EditRealm = () => {
    const realm = useSelector(state => state.realmReducer.realm)
    const initialValues = {
        id: realm.id,
        name: realm.name,
        description: realm.description,
        startingText: realm.startingText,
        codex: realm.codex,
        document: realm.document
    }

    return (
        <RealmEditor
            title="Редактирование области:"
            initialValues={initialValues}
            action={editTheRealm}
            message="отредактирована"
            realmId={realm.id}
        />
    )
}
export default EditRealm
