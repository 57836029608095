import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import ActiveUserMenu from './UserMenu/ActiveUserMenu'
import NotActiveUserMenu from './UserMenu/NotActiveUserMenu'
import {getUser} from '../../redux/reducers/userSlise'


const UserMenu = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUser())
    }, [dispatch])
    const userStatus = useSelector(state => state.userSlice.profile?.status)
    return userStatus === "active" || userStatus === "moderator" ?
        <ActiveUserMenu/>: <NotActiveUserMenu/>
}

export default UserMenu

