import React from 'react';
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom';
import RealmEditorMessageModal from './RealmEditorMessageModal'
import RealmEditorActionModal from './RealmEditorActionModal'
import RealmEditorDelMessageModal from './RealmEditorDelMessageModal'
import {buttonStyle} from './styles'
import "./AmendmentsEditor.css";
import {Header, Segment, Form, Button, Container} from 'semantic-ui-react'

const RealmEditorForm = ({
                             formik, handleChangeName, handleChangeDescription, handleChangeStartingText,
                             handleChangeCodex, title, initialValues, action, message, docId, realmId,
                             handleOpenModal, handleClose, newRealmId, openModal, handleCloseModal,
                             setOpenDel, openDel, open
                         }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const errorMessage = formik.errors
    const ifFieldTouched = formik.touched

    return (
        <Segment basic className="basicSegment">
            <span>
            <Header as='h2' textAlign='center'>
    <Header.Content>
        {title}
    </Header.Content>
            </Header>
        </span>
            <Form style={{width: "100%"}}
                  onSubmit={formik.handleSubmit}>
                <Container>
                    <Form.TextArea
                        label='Название области'
                        placeholder='Название области'
                        onChange={handleChangeName}
                        name='name'
                        error={errorMessage.name && ifFieldTouched.name ?
                            errorMessage.name : null}
                        {...formik.getFieldProps('name')}
                    />
                    <Form.TextArea
                        label='Описание области:'
                        placeholder='Описание области:'
                        onChange={handleChangeDescription}
                        name='description'
                        error={errorMessage.description && ifFieldTouched.description ?
                            errorMessage.description : null}
                        {...formik.getFieldProps('description')}
                    />
                    <Form.TextArea
                        label='Текст, начинающий статью поправочного закона'
                        placeholder='Текст, начинающий статью поправочного закона'
                        onChange={handleChangeStartingText}
                        name='startingText'
                        error={errorMessage.startingText && ifFieldTouched.startingText ?
                            errorMessage.startingText : null}
                        {...formik.getFieldProps('startingText')}
                    />
                    <Form.TextArea
                        label='Ссылки на Собрание Законодательства РФ'
                        placeholder='Ссылки на Собрание Законодательства РФ'
                        onChange={handleChangeCodex}
                        name='codex'
                        error={errorMessage.codex && ifFieldTouched.codex ?
                            errorMessage.codex : null}
                        {...formik.getFieldProps('codex')}
                    />
                    <Button
                        style={{marginTop: "1%"}}
                        type='submit'
                        id='form-button-control'
                        disabled={!formik.isValid}
                    >Сохранить</Button>
                    <Button
                        onClick={() => {
                            navigate(`/user/docs/realms/${docId}`)
                        }}
                        style={{marginTop: "1%"}}
                    >Отмена</Button>
                    {location.pathname === `/editrealm/${docId}/${realmId}` &&
                    (<Button
                        type='button'
                        onClick={handleOpenModal}
                        color="red"
                        inverted
                        floated='right'
                        style={buttonStyle}>
                        Удалить</Button>)}
                </Container>
                <RealmEditorMessageModal
                    open={open} handleClose={handleClose}
                    newRealmId={newRealmId} docId={docId}
                    realmId={realmId} message={message}/>
                <RealmEditorActionModal
                    openModal={openModal} handleCloseModal={handleCloseModal}
                    setOpenDel={setOpenDel} realmId={realmId}
                    realmName={formik.values.name}/>
                <RealmEditorDelMessageModal
                    openDel={openDel}
                    setOpenDel={setOpenDel}
                    docId={docId}/>
            </Form>
        </Segment>
    );
};
export default RealmEditorForm;
