import React from 'react'
import MainText from '../documents/MainText'
import PDFViewer from './Body'
import Footer from './Footer'


const MainPage = () => {
    return (
            <div>
                <MainText/>
                <PDFViewer/>
                <Footer/>
            </div>
    )
}
export default MainPage
