

export const initialValues = {
    id: '',
    realm: '',
    mainText: '',
    supplementaryText: '',
    financeText: '',
    revocationText: '',
    lawyer: {},
    moderationComment: '',
    moderationStatus: '',
    updateDate: '',
    codex: ''
}

export const validate = (values) => {
    let errors = {}
    if (!values.mainText.trim()) {
        errors.mainText = "Пожалуйста, заполните это поле"
    }
    if (!values.codex) {
        errors.codex = "Пожалуйста, заполните это поле"
    }
    return errors
}



