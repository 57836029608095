import React from 'react'
import {Segment} from 'semantic-ui-react'


const Terms = () => {
    return (
            <Segment>
                <h3>Условия использования</h3>

                <div>Последнее обновление: 22/06/2022</div>

                Пожалуйста, внимательно ознакомьтесь с настоящими Условиями использования ("Условия", "Условия использования") перед использованием https://form.sanatsia.com/ (далее "Сайт"), сервиса Санации Права ("мы", "нас" или "наш").

                <h3>1. Принятие условий</h3>
                Посещая или используя Сайт, вы соглашаетесь соблюдать настоящие Условия. Если вы не согласны с любой частью Условий, вам запрещается получать доступ к Сайту.

                <h3>2. Использование Сайта</h3>
                <h4>2.1 Содержание и интеллектуальная собственность</h4>

                Сайт и его оригинальное содержание, функции и возможности принадлежат Санации права и защищены международными законами об авторском праве, товарных знаках, патентах, коммерческой тайне и другими правами интеллектуальной собственности. Вы не можете изменять, воспроизводить, распространять, создавать производные работы, публично отображать или выполнять, а также использовать содержимое Сайта, полностью или частично, без предварительного письменного согласия.

                <h4>2.2 Запрещенное поведение</h4>

                Вы соглашаетесь не совершать следующие запрещенные действия:

                Использовать Сайт таким образом, который может привести к его выключению, перегрузке, повреждению или нарушению работы Сайта или препятствовать использованию Сайта другими пользователями.
                Пытаться получить несанкционированный доступ к любой части Сайта, учетным записям других пользователей или компьютерным системам или сетям, связанным с Сайтом.
                Загружать, передавать или распространять компьютерные вирусы, черви или любое программное обеспечение, предназначенное для повреждения или изменения компьютерной системы или данных.
                Собирать или хранить личные данные о других пользователях без их явного согласия.
                Использовать Сайт в незаконных или несанкционированных целях.

                <h3>3. Ссылки на веб-сайты третьих сторон</h3>
                Сайт может содержать ссылки на веб-сайты или услуги третьих сторон, не принадлежащие или не контролируемые нами. Мы не контролируем и не несем ответственности за содержимое, политику конфиденциальности или практики таких веб-сайтов или услуг. Вы признаете и соглашаетесь с тем, что мы не несем ответственности за любой ущерб или потерю, возникшие в результате использования такого контента, товаров или услуг, доступных на или через такие веб-сайты или услуги.

                <h3>4. Ограничение ответственности</h3>
                В никаком случае Санация Права, его директоры, сотрудники, партнеры, агенты, поставщики или аффилированные лица не несут ответственности за косвенные, случайные, особые, последовательные или карательные ущербы, включая, но не ограничиваясь, потерю прибыли, данных, использования, доброго имени или иных нематериальных потерь, возникших в результате доступа к Сайту, его использования или невозможности получить доступ или использовать Сайт.

                <h3>5. Изменения условий</h3>
                Мы оставляем за собой право по собственному усмотрению изменять или заменять настоящие Условия в любое время. В случае существенного изменения мы предоставим уведомление не менее 30 дней до вступления в силу новых условий. Что считается существенным изменением будет определено нашим собственным усмотрением.

                <h3>6. Применимое право</h3>
                Настоящие Условия регулируются и толкуются в соответствии с законами РФ. Любой спор, возникающий из или в связи с настоящими Условиями, подлежит рассмотрению исключительно компетентными судами, находящимися в РФ.

                <h3>7. Связь с нами</h3>
                Если у вас возникли вопросы по поводу настоящих Условий, пожалуйста, свяжитесь с нами по следующим контактным данным:
                info@sanatsia.com

                Используя Сайт, вы признаете, что ознакомились, поняли и согласны соблюдать настоящие Условия использования.
                ми настоящей Политики конфиденциальности.
            </Segment>
    )
}
export default Terms
