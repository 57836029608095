import {createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api';

const initialState = {
    realm_request_id: ""
}

export const lawyerRealmSlice = createSlice({
    name: 'grantLawyerRealm',
    initialState,
    reducers: {
        grantLawyerRealm: (state, action) => {
            return {
                ...state,
                realm_request_id: action.payload.realm_request_id
            };
        },
        revokeLawyerRealm: (state, action) => {
            return {
                ...state,
                realm_request_id: action.payload.realm_request_id
            };
        },
    }
})

export const {grantLawyerRealm, revokeLawyerRealm} = lawyerRealmSlice.actions;

export const grantTheLawyerRealm = (realm_request_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/lawyers/grant/${realm_request_id}`)
            .then((response) => {
                dispatch(grantLawyerRealm({realm_request_id}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
}

export const revokeTheLawyerRealm = (realm_request_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/lawyers/reject/${realm_request_id}`)
            .then((response) => {
                dispatch(revokeLawyerRealm({realm_request_id}));
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
}

export default lawyerRealmSlice.reducer;
