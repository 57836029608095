import {useSelector} from 'react-redux'

export function useComments () {
    const comments = useSelector(state => state.getCommentsReducer.comments)
    return {
        comments,
        countOfMainComments: comments.filter(it => it.commented === "main").length,
        countOfFinanceComments: comments.filter(it => it.commented === "finance").length,
        countOfRevocationComments: comments.filter(it => it.commented === "revocation").length,
        countOfSupplementaryComments: comments.filter(it => it.commented === "supplementary").length
    }
}
