import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios'



export const getAccessToken = createAsyncThunk(
    'csrf/getCsrfToken',
    async () => {
    /* reading GET params to pass it over */
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let entries = urlParams.entries();
    let params = Object.fromEntries(entries);

    return axios.get('https://api.sanatsia.com/back/1.0.0/logging', {
        params: params,
        withCredentials: true,
        })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                if (response.data.access_token) {
                    localStorage.setItem("token", response.data.access_token)
                    return response.data.access_token
                }
                else localStorage.setItem("token", "90|rJWtOMziALiWzN7dVFNlneMYQQuCw8pcmtTFQiuG")
                return "90|rJWtOMziALiWzN7dVFNlneMYQQuCw8pcmtTFQiuG"
            }
            return response.data.access_token
                ? response.data.access_token: "90|rJWtOMziALiWzN7dVFNlneMYQQuCw8pcmtTFQiuG"
        })
        .catch((error) => {
            alert("Oшибка авторизации")
        })
});


const initialState = {
    accessToken: ''
}

const accessSlice = createSlice({
    name: 'access',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAccessToken.fulfilled, (state, action) => {
            state.accessToken = action.payload;
        });
    },
});

export default accessSlice.reducer;
