import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile, clearUploadStatus } from '../../redux/reducers/fileUploadSlise';
import {Icon, Button} from 'semantic-ui-react'

const FileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch();
    const { isLoading, error, success } = useSelector(state => state.uploadReducer);

    const handleFileInput = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        dispatch(uploadFile(selectedFile));
    };

    const handleClear = () => {
        setSelectedFile(null);
        dispatch(clearUploadStatus());
    };

    return (
        <div>
            <input type="file"
                   className="inputStyle"
                   onChange={handleFileInput}
                   accept=".pdf,.png,.jpg,.jpeg" />
            <Button size="mini" onClick={handleUpload} disabled={!selectedFile || isLoading}>Загрузить</Button>
            {isLoading && <Icon loading name='spinner' size='massive'/>}
            {error && <p>Ошибка: {error}</p>}
            {success && (
                <div>
                    <p>Файл успешно загружен!</p>
                    <Button size="mini" onClick={handleClear}>Закрыть</Button>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
