import {createSlice} from '@reduxjs/toolkit';
import {instance} from '../../api/api';

const initialState = {
    coverLetter: "",
    contact: "",
    socialLink: ""
};

export const postContactSlice = createSlice({
    name: 'userContact',
    initialState,
    reducers: {
        addContact: (state, action) => {
            return {
                ...state,
                coverLetter: action.payload.coverLetter,
                contact: action.payload.contact,
                socialLink: action.payload.socialLink,
            };
        }
    },
});

export const {addContact} = postContactSlice.actions;

export const addUserContact = (coverLetter, contact, socialLink) => (dispatch) => {
    return new Promise((resolve, reject) => {
        instance.post(`/profile`, {
            coverLetter: coverLetter,
            contact: contact,
            socialLink: socialLink,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert("У Вас нет прав на это действие")
                }
                reject(error);
            });
    });
};


export default postContactSlice.reducer;
