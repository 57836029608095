import React, {useState} from 'react';
import AdminRoutes from '../../../routes/adminRoutes';
import {Icon, Menu, Segment, Sidebar} from 'semantic-ui-react';
import MediaQuery from 'react-responsive';
import EditsMenu from './SidebarEditsMenu'

const AdminEditsDashboard = () => {
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const handleSidebarHide = () => {
        setSidebarVisible(false);
    };

    const handleToggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };
    const gradientStyle = {
        background: 'linear-gradient(to bottom, #3399ff, #fff)',
        boxShadow: 'none'
    };

    return (
        <div style={{height: '100vh'}}>
            <MediaQuery maxWidth={991}>
                <Menu style={{marginBottom: "0px"}}>
                    <Menu.Item onClick={handleToggleSidebar}>
                        <Icon color='orange' size='big' name="list"/>
                    </Menu.Item>
                </Menu>
            </MediaQuery>
            <Sidebar.Pushable as={Segment} style={{margin: "0px"}}>
                <MediaQuery minWidth={992}>
                    <Sidebar
                        as={Menu}
                        animation="push"
                        icon='labeled'
                        direction="left"
                        onHide={handleSidebarHide}
                        inverted
                        color='blue'
                        vertical
                        visible
                        width="thin"
                        style={gradientStyle}
                    >
                        <EditsMenu/>
                    </Sidebar>
                </MediaQuery>
                <MediaQuery maxWidth={991}>
                    <Sidebar
                        as={Menu}
                        animation="push"
                        icon='labeled'
                        direction="left"
                        onHide={handleSidebarHide}
                        inverted
                        color='blue'
                        vertical
                        visible={sidebarVisible}
                        width="thin"
                        style={gradientStyle}
                    >
                        <EditsMenu/>
                    </Sidebar>
                </MediaQuery>
                <Sidebar.Pusher>
                    <Segment basic>
                        <AdminRoutes/>
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>
    );
};
export default AdminEditsDashboard;
