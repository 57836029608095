import React from 'react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./AmendmentsEditor.css";
import ButtonClose from '../Documents/Buttons/ButtonClose'
import CreateAmendmentModal from './CreateAmendmentModal'
import {Header, Segment, Form, Button, Container} from 'semantic-ui-react'


const CreateAmendmentForm = ({
                                 handleCloseForm, formik, modules, handleContentChangeMain,
                                 handleContentFocusOutMain, handleContentChangeSupplementary,
                                 handleContentChangeFinance, handleContentChangeRevocation, handleContentChangeCodex,
                                 handleClearForm, handleClose, amendmentId, open
                             }) => {
    const errorMessage = formik.errors
    const ifFieldTouched = formik.touched

    return (
        <Segment basic className="basicSegment">
            <ButtonClose handleClose={handleCloseForm}/>
            <span>
            <Header as='h2' textAlign='center'>
    <Header.Content>
      Для предложения поправки заполните форму:
    </Header.Content>
            </Header>
        </span>

            <Form style={{width: "100%"}}
                  onSubmit={formik.handleSubmit}>
                <Container>
                    <div style={{marginTop: "1%"}}><label>Текст поправки</label></div>
                    <ReactQuill
                        contentEditable="true"
                        modules={modules}
                        placeholder='Текст поправки'
                        name="mainText"
                        onChange={handleContentChangeMain}
                        value={formik.values.mainText}
                        onFocusOut={handleContentFocusOutMain}
                        onBlur={handleContentFocusOutMain}/>
                    {errorMessage.mainText && ifFieldTouched.mainText ?
                        <div className="ui pointing above prompt label" role="alert" aria-atomic="true"
                             title={errorMessage.mainText}>
                            {errorMessage.mainText}</div> : null}
                    <div style={{marginTop: "1%"}}><label>Пояснительная записка к поправке</label></div>
                    <ReactQuill
                        contentEditable="true"
                        modules={modules}
                        placeholder='Текст пояснительной записки'
                        name="supplementaryText"
                        onChange={handleContentChangeSupplementary}
                        value={formik.values.supplementaryText}/>

                    <div style={{marginTop: "1%"}}><label style={{marginTop: "1%"}}>Напишите финансово-экономическое
                        обоснование или оставьте поле пустым,
                        если изменения закона о Бюджете не требуется</label></div>
                    <ReactQuill
                        contentEditable="true"
                        modules={modules}
                        placeholder='Финансово-экономическое обоснование'
                        name="financeText"
                        onChange={handleContentChangeFinance}
                        value={formik.values.financeText}/>

                    <div style={{marginTop: "1%"}}><label>Напишите, какие законы или иные НПА потребуется
                        изменить или отменить, или оставьте поле пустым</label></div>
                    <ReactQuill
                        contentEditable="true"
                        modules={modules}
                        placeholder='Перечень законов и иных НПА подлежащих отмене или изменению'
                        name="revocationText"
                        onChange={handleContentChangeRevocation}
                        value={formik.values.revocationText}/>
                    <div style={{marginTop: "10px"}}>
                        <Form.TextArea
                            name='codex'
                            label='Ссылки на Собрание Законодательства РФ'
                            placeholder='Ссылки на Собрание Законодательства РФ'
                            onChange={handleContentChangeCodex}
                            value={formik.values.codex}
                            error={errorMessage.codex && ifFieldTouched.codex ?
                                errorMessage.codex : null}
                            {...formik.getFieldProps('codex')}/>
                    </div>
                    <Button
                        style={{marginTop: "1%"}}
                        type='submit'
                        id='form-button-control'
                        disabled={!formik.isValid}
                    >Сохранить</Button>
                    <Button
                        style={{marginTop: "1%"}}
                        type="button"
                        onClick={handleClearForm}
                    >Очистить поля ввода</Button>
                </Container>
                <CreateAmendmentModal open={open} handleClose={handleClose} amendmentId={amendmentId}/>
            </Form>
        </Segment>
    )
}
export default CreateAmendmentForm


