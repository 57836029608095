import React from 'react';
import {useNavigate} from 'react-router-dom'
import {Button, Modal, Icon} from 'semantic-ui-react'

const AmendmentEditorModal = ({open, handleClose, id}) => {
    const navigate = useNavigate()
    return (
        <Modal
            open={open}
            basic size='mini'>
            <Button
                style={{width: "250px"}}
                onClick={() => {
                    handleClose();
                    navigate('/user/myamendments')
                }}>
                Поправка успешно отредактирована
            </Button>
            <Button
                style={{marginTop: "1%", width: "250px"}}
                onClick={() => {
                    handleClose();
                    navigate(`/amendment/${id}`)
                }}>
                Посмотреть
                <Icon name='check'
                      color="orange"
                      size='big'
                      style={{
                          marginLeft: "10px",
                          marginBottom: "5px"
                      }}/>
            </Button>
        </Modal>
    );
};

export default AmendmentEditorModal;
