import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import FileUpload from './FileUpload'
import {getUser} from '../../redux/reducers/userSlise'
import NewUserForm from './NewUserForm'
import {Image, Header, Segment, Icon, Message} from 'semantic-ui-react'
import './UserPage.css'

const UserPage = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.userSlice.profile)
    const socialLink = user?.socialLink
    const coverLetter = user?.coverLetter
    const contact = user?.contact
    const userStatus = user?.status

    useEffect(() => {
        if (!user) {
            dispatch(getUser())
        } else {
            localStorage.setItem("status", user?.status)
        }
        // eslint-disable-next-line
    }, [])
    return (<div>
        <Segment basic>
            {user?.avatar ?
                (<Image circular src={user?.avatar} avatar size='tiny'/>)
                : (<Image circular src='https://cdn-icons-png.flaticon.com/512/6596/6596121.png' avatar size='tiny'/>)}
            <span>
                    <Header as='h2'>
                        <Header.Content>
                            {user?.name}
                            <Header.Subheader>{user?.email}</Header.Subheader>
                        </Header.Content>
                    </Header>
                </span>
        </Segment>
        {userStatus === "banned" || userStatus === "denied" ?
            <Message
                error
                style={{width: "300px"}}
                header='Пользователь заблокирован'/> : null}
        {userStatus === "new" ?
            <><Message
                color='green'
                style={{
                    width: "90%",
                    textAlign: "center"
                }}
               >
                <Message.Header>Для того,чтобы присоединиться к проекту, пожалуйста, напишите немного о себе.
                    <div>
                        Если вы это уже сделали, подождите проверки Вашей регистрации и обновите страницу.
                    </div>
                    </Message.Header>
                <p style={{
                    color: "black"
                }}> Только пользователям, которые  написали сопроводительное письмо (графа "О себе" ниже) и оставили контактные
                    данные, по решению модератора будет предоставлена возможность присоединиться к экспертной группе и предлагать
                    к рассмотрению статьи законодательства, которые, по их мнению, подлежат отмене или изменению. <br/>
                    Это необходимо в целях безопасности. Спасибо за понимание.</p>
            </Message>
            </> : null}
        <Segment style={{width: "90%"}}>
            {!user?.email ? (<div className="preloader">
                    <Icon loading name='spinner' size='massive'/>
                    <div><h2>Loading...</h2></div>
                </div>) :
                (<><h3>Загрузите файл диплома:</h3>
                    <FileUpload/>
                    {user?.professionalFile !== "" && user?.professionalFile !== null &&
                    <div style={{color: "#3399ff", marginTop: "1rem", cursor: "pointer"}}
                         onClick={() => {
                             window.open
                             (`https://api.sanatsia.com/storage/diplomas/${user?.professionalFile}`,
                                 '_blank')
                         }}>Посмотреть файл диплома
                    </div>
                    }
                    {userStatus === "new" ? <NewUserForm
                        userContact={contact}
                        userSocialLink={socialLink}
                        userCoverLetter={coverLetter}
                    /> : null}
                </>)}
        </Segment>
    </div>)
}

export default UserPage

