import React from 'react'
import {Icon, Label} from 'semantic-ui-react'
import ButtonBack from './../Buttons/ButtonBack'

const AmendmentHead = ({formattedDate, status, lawyerName, color, id}) => {
    return (
        <>
            <ButtonBack/>
            <Icon name='calendar alternate outline'/><span key={`${id}6`}>{formattedDate}</span>
            <Icon style={{marginLeft: "5px"}} name='user'/><span
            key={`${id}4`}>{lawyerName}</span>
            <Label key={`${id}7`} color={color} style={{marginLeft: "5px"}}>
                {status}
            </Label>
        </>
    )
}
export default AmendmentHead
