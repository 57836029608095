export const handleTitle = (status, type) => {
    let title = ""
    if (status === "succeeded") {
        if (type === "fz") {
            title = "ФЗ"
        } else if (type === "constitution") {
            title = "ст. Конституции"
        } else if (type === "fkz") {
            title = "ФКЗ"
        }
    }
    return title
}
