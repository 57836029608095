import React, {useEffect} from 'react';
import {Grid} from 'semantic-ui-react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchDocuments} from '../redux/reducers/getDocsSlise'
import PdfPreview from './Preview'


const PDFViewer = () => {
    const dispatch = useDispatch()
    const documents = useSelector(state => state.docsReducer.documents)
    useEffect(() => {
        dispatch(fetchDocuments())
    }, [dispatch])
    return (
        <Grid container centered columns={3} stackable style={{marginTop: "1%"}}>
            <Grid.Row>
                {documents.map((document, index) => {
                    return <Grid.Column key={index}>
                        <PdfPreview header={document.description}
                                    pdf={`https://api.sanatsia.com/storage/${document.filename}`}/>
                    </Grid.Column>})
                }
            </Grid.Row>
        </Grid>
    );
};

export default PDFViewer;
