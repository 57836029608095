import React from 'react'
import {Modal, Message, Button} from 'semantic-ui-react'
import {fetchOneAmendment} from '../../../../redux/reducers/getOneAmendmentSlice'
import AmendmentCancelButton from '../Buttons/AmendmentCancelButton'
import AmendmentMessageButton from '../Buttons/AmendmentMessageButton'
import AmendmentActionButton from '../Buttons/AmendmentActionButton'
import AmendmentCard from './AmendmentCard'
import AmendnentHead from './AmendmentHead'
import OneAmendmentActionButton from './../Buttons/OneAmendmentActionButton'
import ModeratorProposedButtons from './ModeratorProposedButtons'
import {amendmentStyle, draftAmendmentStyle} from './styles'
import './MyAmendmentsList.css'

const AmendmentMessage = ({
                              loadingStatus, color, modStatus, formattedDate, amendment, status,
                              userId, lawyerId, id, userStatus, open, setOpen, openDel,
                              setOpenDel, openProp, setOpenProp, proposeOK, setProposeOK,
                              openAccept, setOpenAccept, acceptOK, setAcceptOK,
                              handleRevoke, handleDeny, handleAccept, handleDelete, handlePropose
                          }) => {
    return (
        <Message color={color}
                 style={modStatus === "draft" ?
                     draftAmendmentStyle :
                     amendmentStyle}>
            <div style={{fontSize: "15px"}}>
                <AmendnentHead
                    id={id}
                    formattedDate={formattedDate}
                    lawyerName={amendment.lawyer?.name}
                    status={status}
                    color={color}/>
                {/*кнопки, отображающиеся в зависимости от статуса поправки*/}
                {userId === lawyerId ?
                    <>
                        {amendment.moderationStatus !== "published" ?
                            <> <OneAmendmentActionButton
                                action={setOpen}
                                color="blue"
                                message="Удалить поправку"
                                path={null}
                                reload={null}/>
                                <OneAmendmentActionButton
                                    action={null}
                                    color="blue"
                                    message="Редактировать"
                                    path={`/editamendment/${id}`}
                                    reload={fetchOneAmendment(id)}/>
                            </> : null
                        }
                        {amendment.moderationStatus !== "published" &&
                        amendment.moderationStatus !== "proposed" ?
                            <Button
                                onClick={() => {
                                    setOpenProp(true)
                                }}
                                className="triggerButton"
                                size='mini'
                                style={{marginBottom: "2px"}}
                                color="green">
                                Отправить для публикации
                            </Button>
                            : null}
                    </> : null}
                {userStatus === "moderator" && amendment.moderationStatus === "published" ?
                    <>
                        <Button
                            onClick={handleRevoke}
                            className="triggerButton"
                            size='mini'
                            style={{marginBottom: "2px", backgroundColor: "#3399ff"}}
                            color="blue">
                            Вернуть на доработку
                        </Button>
                        <OneAmendmentActionButton
                            action={null}
                            color="blue"
                            message="Редактировать"
                            path={`/editamendment/${id}`}
                            reload={fetchOneAmendment(id)}/>
                    </> : null}
                {amendment.moderationStatus === "proposed" && userStatus === "moderator" ?
                    <ModeratorProposedButtons
                        setOpenAccept={setOpenAccept}
                        handleDeny={handleDeny}
                        fetchOneAmendment={fetchOneAmendment}
                        id={id}
                    /> : null}
                {amendment.moderationStatus === "proposed" && userStatus === "moderator" && userId !== lawyerId ?
                    <OneAmendmentActionButton
                        action={null}
                        color="blue"
                        message="Редактировать"
                        path={`/editamendment/${id}`}
                        reload={fetchOneAmendment(id)}/> : null}
            </div>
            <AmendmentCard
                id={id}
                lawyerId={lawyerId}
                mainText={amendment.mainText}
                supplementaryText={amendment.supplementaryText}
                financeText={amendment.financeText}
                revocationText={amendment.revocationText}
                moderationComment={amendment.moderationComment}
                status={status}
                moderStatus={amendment.moderationStatus}
                codex={amendment.codex}/>
            {/*модальные окна, открывающиеся при нажатии на ту или иную кнопку*/}
            {/*удаление поправки*/}
            <Modal
                open={open}
                basic size='mini'>
                <AmendmentActionButton
                    handler={handleDelete}
                    message="Нажмите, чтобы удалить поправку"/>
                <AmendmentCancelButton action={setOpen}/>
            </Modal>
            <Modal
                open={openDel}
                basic size='mini'>
                <AmendmentMessageButton
                    path={-2}
                    action={setOpenDel}
                    message="Поправка удалена"
                    id={null}
                    reload={null}/>
            </Modal>
            {/*предложение поправки*/}
            <Modal
                open={openProp}
                basic size='mini'>
                <AmendmentActionButton
                    handler={handlePropose}
                    message="Нажмите, чтобы предложить поправку"/>
                <AmendmentCancelButton action={setOpenProp}/>
            </Modal>
            <Modal
                open={proposeOK}
                basic size='mini'>
                <AmendmentMessageButton
                    path={`/amendment/${id}`}
                    action={setProposeOK}
                    message="Поправка предложена"
                    id={id}
                    reload={fetchOneAmendment}/>
            </Modal>
            {/*принятие поправки*/}
            <Modal
                open={openAccept}
                basic size='mini'>
                <AmendmentActionButton
                    handler={handleAccept}
                    message="Нажмите, чтобы принять поправку"/>
                <AmendmentCancelButton action={setOpenAccept}/>
            </Modal>
            <Modal
                open={acceptOK}
                basic size='mini'>
                <AmendmentMessageButton
                    path={`/amendment/${id}`}
                    action={setAcceptOK}
                    message="Поправка принята"
                    id={id}
                    reload={fetchOneAmendment}/>
            </Modal>
        </Message>
    )
}
export default AmendmentMessage
