import React from 'react'
import {useMatch} from "react-router-dom";
import AmendmentEditor from '../components/User/Edits/AmendmentsEditor'
import EditRealm from '../components/User/Edits/EditRealm'
import AddRealm from '../components/User/Edits/AddRealm'
import OneAmendment from '../components/User/Documents/Amendments/OneAmendment'


function EditRoutes() {
    const matchAmendment = useMatch('editamendment/:id')
    const matchRealm = useMatch('editrealm/:docId/:id')
    const matchNewRealm = useMatch('addrealm/:docId')
    const matchOneAmendment = useMatch('/amendment/:id')
    if (matchAmendment) {
        return (<AmendmentEditor/>)
    } else if (matchOneAmendment) {
        return <OneAmendment/>
    } else if (matchRealm) {
        return <EditRealm/>
    } else if (matchNewRealm) {
        return <AddRealm/>
    }
}

export default EditRoutes;
