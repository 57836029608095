import React from 'react'
import {useMatch} from "react-router-dom";
import Request from '../components/Admin/Users/Request'
import NewUsers from '../components/Admin/Users/New'
import Approved from '../components/Admin/Users/Approved'
import Banned from '../components/Admin/Users/Banned'
import Disallawed from '../components/Admin/Users/Disallawed'
import RequestEdits from '../components/Admin/Edits/RequestEdits'
import DisallawedEdits from '../components/Admin/Edits/DisallawedEdits'
import ApprovedEdits from '../components/Admin/Edits/ApprovedEdits'
import PreparedAmendments from '../components/Admin/Edits/PreparedAmendmentsList'

function AdminRoutes() {
    const match = useMatch('/dashboard/requests');
    const matchNew = useMatch('/dashboard/new')
    const matchApproved = useMatch('/dashboard/approved')
    const matchBanned = useMatch('/dashboard/banned')
    const matchDisallow = useMatch('/dashboard/disallow')
    const matchRequests = useMatch('/editdashboard/requests')
    const matchApprovedEdits = useMatch('/editdashboard/approved')
    const matchDisallowEdits = useMatch('/editdashboard/disallow')
    const matchPrepared = useMatch('/editdashboard/prepared')
    if (match) {
        return (<Request/>)
    } else if (matchApproved) {
        return (<Approved/>)
    } else if (matchBanned) {
        return (<Banned/>)
    } else if (matchDisallow) {
        return (<Disallawed/>)
    } else if (matchRequests) {
        return (<RequestEdits/>)
    } else if (matchApprovedEdits) {
        return (<ApprovedEdits/>)
    } else if (matchDisallowEdits) {
        return (<DisallawedEdits/>)
    } else if (matchPrepared) {
        return (<PreparedAmendments/>)
    } else if (matchNew) {
        return (<NewUsers/>)
    }
}

export default AdminRoutes;
