import React from 'react';
import {NavLink} from 'react-router-dom';
import {Icon, Menu} from 'semantic-ui-react';


const ActiveUserMenu = () => (
    <div>
        <Menu.Item as={NavLink} to="/user/profile">
            <Icon name="user"/>
            Профиль
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/user/docs'}>
            <Icon name='edit outline'/>
            Предложить поправки
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/user/myamendments'}>
            <Icon name='folder open outline'/>
            Мои поправки
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/user/myrequests'}>
            <Icon name='question'/>
            Мои запросы
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/user/manual'}>
            <Icon name='file alternate outline'/>
            Инструкция
        </Menu.Item>
    </div>
)

export default ActiveUserMenu

